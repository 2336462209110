/**
 * Copyright © 2024 Grant D. Powell and Parleii LLC
 *
 * This code is closed source and is intended solely for the use of Grant D. Powell or Parleii LLC. 
 * All rights reserved. No part of this code may be reproduced, distributed, or transmitted 
 * in any form or by any means without the prior written permission of the copyright owners.
 *
 * Grant D. Powell retains primary rights to this code, with Parleii LLC holding rights for internal use and development. 
 * Any commercial use or distribution outside of Parleii LLC requires the explicit permission of Grant D. Powell.
 * 
 * "Parleii LLC" refers to the legal entity and its authorized employees, contractors, and agents.
 *
 * This project includes open-source components licensed under MIT and Apache 2.0 licenses:
 * - @emotion/react (MIT)
 * - @emotion/styled (MIT)
 * - @mui/icons-material (MIT)
 * - @mui/material (MIT)
 * - @testing-library/jest-dom (MIT)
 * - @testing-library/react (MIT)
 * - @types/base-64 (MIT)
 * - @types/react-dom (MIT)
 * - @types/react (MIT)
 * - avrgirl-arduino (MIT)
 * - base-64 (Unlicense)
 * - eslint-config-react-app (MIT)
 * - js-chacha20 (MIT)
 * - react-7-segment-display (MIT)
 * - react-bulb (MIT)
 * - react-dom (MIT)
 * - react-scripts (MIT)
 * - react (MIT)
 * - serialterminal (MIT)
 * - typescript (Apache 2.0)
 * - web-vitals (Apache 2.0)
 *
 * The above licenses apply only to their respective components. 
 * For licensing inquiries, please contact Grant D. Powell at grantdpowell911@gmail.com.
 */
import React, { useState, useRef } from 'react';
import { Paper, Typography, Button, TextField, Box, IconButton, Checkbox, FormControlLabel, Snackbar, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IOTable from './IOTable';
import { encryptLab, decryptLab } from '../modules/Crypter';
import '../styling/TestingPages.css';
import HelpIcon from '@mui/icons-material/Help'; // Import the Help ico

const LabCreation: React.FC = () => {
    const [rowCount, setRowCount] = useState(8);
    const [inputTable, setInputTable] = useState(Array(rowCount).fill(Array(7).fill('0')));
    const [outputTable, setOutputTable] = useState(Array(rowCount).fill(Array(7).fill('0')));
    const [isEditable, setIsEditable] = useState(false);
    const [secondaryPassword, setSecondaryPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const csvInputRef = useRef<HTMLInputElement>(null);

    const paperOnRoot = '#3326';           // Background color for the main paper component
    const parleiiGreen = '#4f5024';        // Parleii green color
    const parleiiBlue = '#4f46e5';         // Parleii blue color



    // resets the tables back to 8 rows
    const resetLabCreation = () => {
        setRowCount(8);
        setInputTable(Array(8).fill(Array(7).fill('0')));
        setOutputTable(Array(8).fill(Array(7).fill('0')));
        setIsEditable(false);
        setSecondaryPassword('');
    };

    const resetInputValue = (inputRef: React.RefObject<HTMLInputElement>) => {
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    const downloadEncryptedLab = (encryptedLabData: { nonce: string, data: string }) => {
        let fileName = window.prompt("Enter a name for the encrypted lab file:", "NewLab");
        // add _editable to the file name if it is editable

        if (isEditable && fileName) {
            fileName += "_editable";
        }

        if (fileName) {
            const ilcData = isEditable
                ? `${secondaryPassword}!${encryptedLabData.nonce}\r\n${encryptedLabData.data}`
                : `${encryptedLabData.nonce}\r\n${encryptedLabData.data}`;

            const blob = new Blob([ilcData], { type: "text/plain" });
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = `${fileName}.digilab`;
            link.click();

            URL.revokeObjectURL(url);
        }
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        resetInputValue(inputRef); // Reset input value
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const labData = e.target?.result as string;

                if (!labData) {
                   //console.error('No data found in the uploaded file.');
                    setError('No data found in the uploaded file.');
                    return;
                }

                let splitData: string[] = [];
                let dataToDecrypt = labData;

                if (labData.includes('!')) {
                    splitData = labData.split('!');
                    const userPassword = window.prompt("Enter the password for this lab file:");
                    if (userPassword !== splitData[0]) {
                        alert('Incorrect password!');
                        return;
                    }
                    setSecondaryPassword(userPassword);
                    setIsEditable(true);
                    dataToDecrypt = splitData[1];
                } else {
                    alert('This lab file is not editable.');
                    return;
                }

                const [nonce, data] = dataToDecrypt.split(/\r?\n/);

                if (!nonce || !data) {
                   //console.error('Failed to split nonce and data.');
                    setError('Failed to split nonce and data.');
                    return;
                }

                const decryptedLab = decryptLab(nonce, data);

                if (decryptedLab) {
                   //console.log('Decrypted Lab:', decryptedLab);

                    const lines = decryptedLab.split('\n').map(line => line.trim());
                    const inputs = lines.map(line => line.split('|')[0].replace(/^,|,$/g, '')).join('\n');
                    const outputs = lines.map(line => line.split('|')[1].replace(/^,|,$/g, '')).join('\n');

                    if (!inputs || !outputs) {
                       //console.error('Failed to split inputs and outputs.');
                        setError('Failed to split inputs and outputs.');
                        return;
                    }

                    const inputArray = inputs.split('\n').map(row => row.trim().split(','));
                    const outputArray = outputs.split('\n').map(row => row.trim().split(','));

                    setInputTable(inputArray);
                    setOutputTable(outputArray);
                    setRowCount(inputArray.length);
                } else {
                   //console.error('Failed to decrypt lab data.');
                    setError('Failed to decrypt lab data.');
                }
            };
            reader.readAsText(file);
        }
    };

    const handleCSVUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        resetInputValue(csvInputRef); // Reset input value
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const csvData = e.target?.result as string;
    
                if (!csvData) {
                   //console.error('No data found in the uploaded CSV file.');
                    setError('No data found in the uploaded CSV file.');
                    resetLabCreation();
                    return;
                }
    
                const lines = csvData.split('\n').map(line => line.trim());
    
                // Validate the format of each line
                const isValidFormat = lines.every(line => {
                    const [inputs, outputs] = line.split('|').map(part => part.trim());
                    if (!inputs || !outputs) return false;
    
                    const inputBits = inputs.split(',').filter(Boolean);
                    const outputBits = outputs.split(',').filter(Boolean);
    
                    return inputBits.length === 7 && outputBits.length === 7;
                });
    
                if (!isValidFormat) {
                   //console.error('Invalid CSV format.');
                    setError('Invalid CSV format.');
                    resetLabCreation();
                    return;
                }
    
                const inputs = lines.map(line => line.split('|')[0].trim());
                const outputs = lines.map(line => line.split('|')[1].trim());
    
                if (inputs.length !== outputs.length) {
                   //console.error('The number of input and output rows does not match.');
                    resetLabCreation();
                    setError('The number of input and output rows does not match.');
                    return;
                }
    
                const inputArray = inputs.map(row => {
                    const bits = row.split(',').filter(Boolean);
                    return bits;
                });
    
                const outputArray = outputs.map(row => {
                    const bits = row.split(',').filter(Boolean);
                    return bits;
                });
    
                setInputTable(inputArray);
                setOutputTable(outputArray);
                setRowCount(inputArray.length);
            };
            reader.readAsText(file);
        }
    };
    

    const handleInHouseLabCreation = () => {
        const combinedData = inputTable.map((inputRow, index) => {
            return inputRow.join(',') + ',|,' + outputTable[index].join(','); // Ensure commas between characters
        }).join('\r\n');

        const encryptedLab = encryptLab(combinedData);
       //console.log('Encrypted In-House Lab:', encryptedLab);

        downloadEncryptedLab(encryptedLab);
    };

    const handleRowCountChange = (newRowCount: number) => {
        if (newRowCount < 1 || newRowCount > 128) return;
        const updatedInputTable = [...inputTable];
        const updatedOutputTable = [...outputTable];

        // Add or remove rows while preserving existing data
        if (newRowCount > rowCount) {
            const rowsToAdd = newRowCount - rowCount;
            for (let i = 0; i < rowsToAdd; i++) {
                updatedInputTable.push(Array(7).fill('0'));
                updatedOutputTable.push(Array(7).fill('0'));
            }
        } else if (newRowCount < rowCount) {
            updatedInputTable.splice(newRowCount);
            updatedOutputTable.splice(newRowCount);
        }
        if (isNaN(newRowCount)) newRowCount = 1;  // Handle invalid input

        setRowCount(newRowCount);
        setInputTable(updatedInputTable);
        setOutputTable(updatedOutputTable);
    };

    const incrementRowCount = () => {
        handleRowCountChange(rowCount + 1);
    };

    const decrementRowCount = () => {
        handleRowCountChange(rowCount > 1 ? rowCount - 1 : 1);
    };

    const handleCloseSnackbar = () => {
        setError(null);
    };

    return (
        <Paper elevation={3} className='main-paper'>
            <Paper elevation={3} className='main-paper-title'>
                <Typography variant="h6" fontWeight={'bold'} sx={{ml:'20%'}}>
                    Lab Creation
                </Typography>
				{/* Add Help Button */}
				<IconButton
					onClick={() => window.open('https://docs.parleii.com/digilab/one#lab-creation', '_blank')}
					sx={{ marginLeft: 'auto', color: 'white' }} // Adjust position and color
				>
					<HelpIcon />
				</IconButton>
            </Paper>

            <input type="file" accept=".digilab" onChange={handleFileUpload} ref={inputRef} style={{ display: 'none' }} />
            <input type="file" accept=".csv" onChange={handleCSVUpload} ref={csvInputRef} style={{ display: 'none' }} />

            <Box className='box-for-buttons'>
                <Paper elevation={3} className='paper-for-buttons'>
                    <IconButton onClick={decrementRowCount} disabled={rowCount <= 1}>
                        <RemoveIcon />
                    </IconButton>
                    <TextField
                        label="Rows"
                        type="number"
                        value={rowCount}
                        onChange={(e) => handleRowCountChange(parseInt(e.target.value, 10))}
                        inputProps={{ min: 1, max: 128, style: { textAlign: 'center' } }}
                        sx={{
                            width: 100,
                            '& input[type=number]': {
                                MozAppearance: 'textfield',
                                WebkitAppearance: 'none',
                                '&::-webkit-outer-spin-button': { WebkitAppearance: 'none', margin: 0 },
                                '&::-webkit-inner-spin-button': { WebkitAppearance: 'none', margin: 0 }
                            }
                        }}
                    />
                    <IconButton onClick={incrementRowCount} disabled={rowCount >= 128}>
                        <AddIcon />
                    </IconButton>
                    <Button variant="contained" color="primary" onClick={() => csvInputRef.current?.click()} sx={{ mr: 2 , backgroundColor: parleiiBlue}}>
                        Import .csv
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => inputRef.current?.click()} sx={{ mr: 2 , backgroundColor: parleiiBlue}}>
                        Load .digilab
                    </Button>
                    <Button variant="contained" onClick={handleInHouseLabCreation} sx={{ mb: 0 , backgroundColor: parleiiGreen}}>
                        Save Lab
                    </Button>
                    <Button variant="contained" onClick={resetLabCreation} sx={{ mb: 0, ml: 2 , backgroundColor: 'blue', color: 'white'}}>
                        Reset
                    </Button>
                </Paper>
            </Box>




            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isEditable}
                            onChange={(e) => setIsEditable(e.target.checked)}
                            sx={{
                                color: parleiiBlue,
                                '&.Mui-checked': {
                                    color: parleiiGreen,
                                },
                                '&.Mui-unchecked': {
                                    color: parleiiGreen,
                                }
                            }}
                        />
                    }
                    label="Make Lab Editable"
                />
                {isEditable && (
                    <TextField
                        label="Set Edit Access Password"
                        variant="outlined"
                        value={secondaryPassword}
                        onChange={(e) => {
                            const value = e.target.value;
                            // Remove unwanted characters
                            const filteredValue = value.replace(/[!|,]/g, '');
                            setSecondaryPassword(filteredValue);
                        }}
                        sx={{ ml: 2 }}
                    />
                )}
            </Box>




            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
                    <IOTable rows={inputTable} setRows={setInputTable} editable={true} type="input" header='Inputs' />
                    <IOTable rows={outputTable} setRows={setOutputTable} editable={true} type="output" header='Expected Outputs'/>
            </Box>

            {/* Snackbar for Error Notifications */}
            <Snackbar open={!!error} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    {error} <br />
                    Please make sure your CSV is in the correct format. Example format:<br />
                    0,0,0,0,1,1,1,|,1,1,1,0,0,0,0<br />
                    0,1,1,0,0,1,0,|,0,1,0,0,1,1,0<br />
                    0,0,0,0,1,1,1,|,1,1,1,0,0,0,0<br />
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default LabCreation;
