/**
 * Copyright © 2024 Grant D. Powell and Parleii LLC
 *
 * This code is closed source and is intended solely for the use of Grant D. Powell or Parleii LLC. 
 * All rights reserved. No part of this code may be reproduced, distributed, or transmitted 
 * in any form or by any means without the prior written permission of the copyright owners.
 *
 * Grant D. Powell retains primary rights to this code, with Parleii LLC holding rights for internal use and development. 
 * Any commercial use or distribution outside of Parleii LLC requires the explicit permission of Grant D. Powell.
 * 
 * "Parleii LLC" refers to the legal entity and its authorized employees, contractors, and agents.
 *
 * This project includes open-source components licensed under MIT and Apache 2.0 licenses:
 * - @emotion/react (MIT)
 * - @emotion/styled (MIT)
 * - @mui/icons-material (MIT)
 * - @mui/material (MIT)
 * - @testing-library/jest-dom (MIT)
 * - @testing-library/react (MIT)
 * - @types/base-64 (MIT)
 * - @types/react-dom (MIT)
 * - @types/react (MIT)
 * - avrgirl-arduino (MIT)
 * - base-64 (Unlicense)
 * - eslint-config-react-app (MIT)
 * - js-chacha20 (MIT)
 * - react-7-segment-display (MIT)
 * - react-bulb (MIT)
 * - react-dom (MIT)
 * - react-scripts (MIT)
 * - react (MIT)
 * - serialterminal (MIT)
 * - typescript (Apache 2.0)
 * - web-vitals (Apache 2.0)
 *
 * The above licenses apply only to their respective components. 
 * For licensing inquiries, please contact Grant D. Powell at grantdpowell911@gmail.com.
 */
import React from 'react';
import { Paper, Typography, TextField, Button, Grid, IconButton, FormControl, FormLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface StateMachineTableProps {
  word1: string;
  setWord1: React.Dispatch<React.SetStateAction<string>>;
  word2: string;
  setWord2: React.Dispatch<React.SetStateAction<string>>;
  rows: string[][];
  setRows: React.Dispatch<React.SetStateAction<string[][]>>;
  addExtraState: () => void;
  handleWordChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    wordSetter: React.Dispatch<React.SetStateAction<string>>
  ) => void;
  isEditable: boolean;
}

const StateMachineTable: React.FC<StateMachineTableProps> = ({
  word1,
  setWord1,
  word2,
  setWord2,
  rows,
  setRows,
  addExtraState,
  handleWordChange,
  isEditable,
}) => {
  const seg_dict = {
    a: '1110111', b: '0011111', c: '1001110', d: '0111101', e: '1001111', f: '1000111', g: '1111011',
    h: '0010111', i: '0110000', j: '0111000', l: '0001110', n: '0010101', o: '1111110', p: '1100111',
    q: '1110011', r: '0000101', s: '1011011', t: '0001111', u: '0011100', y: '0111011', z: '1101101',
    '1': '0110000', '2': '1101101', '3': '1111001', '4': '0110011', '5': '1011011', '6': '1011111',
    '7': '1110000', '8': '1111111', '9': '1110011', '0': '1111110', '-': '0000001', ' ': '0000000',
  };

  const parleiiGreen = '#4f5024';        // Parleii green color
  const parleiiBlue = '#4f46e5'; 

  const isValidLetter = (letter: string) => seg_dict.hasOwnProperty(letter);

  const filterValidLetters = (input: string) => {
    return input.split('').filter(isValidLetter).join('');
  };

  const handleWordChangeWithValidation = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    wordSetter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const value = e.target.value.toLowerCase();
    const filteredValue = filterValidLetters(value);
    wordSetter(filteredValue);
  };

  const handleLetterChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    col: number
  ) => {
    const value = e.target.value.toLowerCase();
    if (isValidLetter(value)) {
      setRows((prevRows) =>
        prevRows.map((row, i) =>
          i === index
            ? col === 1
              ? [row[0], value, row[2]]  // Change "Starting Letter" without clearing "State After Input"
              : [row[0], row[1], value]  // Change "State After Input" without clearing "Starting Letter"
            : row
        )
      );
    } else {
      setRows((prevRows) =>
        prevRows.map((row, i) =>
          i === index
            ? col === 1
              ? [row[0], '', row[2]]  // Clear the "Starting Letter" field if invalid input is typed
              : [row[0], row[1], '']  // Clear the "State After Input" field if invalid input is typed
            : row
        )
      );
    }
  };

  const toggleStartingState = (index: number) => {
    setRows((prevRows) =>
      prevRows.map((row, i) =>
        i === index ? [(row[0] === '0' ? '1' : '0'), row[1], row[2]] : row
      )
    );
  };

  const removeExtraState = (index: number) => {
    setRows((prevRows) => prevRows.filter((_, i) => i !== index));
  };

  return (
    <Paper elevation={3} sx={{ padding: 1, backgroundColor: 'black', width: 'auto', m: 2 }}>
      <Paper elevation={3} sx={{ padding: 2, m: 1 }}>
        <Paper elevation={3}
          sx={{
            width: '100%',
            padding: 0.5,
            backgroundColor: parleiiBlue,
            textAlign: 'center',
            mb: 2,
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative'
          }}
        >
          <Typography variant='h6' fontWeight={'bold'} sx={{ flex: 1, textAlign: 'center', color: 'black' }}>
            State Machine Configuration
          </Typography>
        </Paper>

        {/* Off State Input */}
        <TextField
          label="Word 1 | S7 = 0 | (Input Off)"
          variant="outlined"
          fullWidth
          value={word1}
          onChange={(e) => handleWordChangeWithValidation(e, setWord1)}
          sx={{ marginBottom: 2 }}
          disabled={!isEditable}
        />

        {/* On State Input */}
        <TextField
          label="Word 2 | S7 = 1 | (Input On)"
          variant="outlined"
          fullWidth
          value={word2}
          onChange={(e) => handleWordChangeWithValidation(e, setWord2)}
          sx={{ marginBottom: 2 }}
          disabled={!isEditable}
        />

        {/* Extra State Inputs */}
        <Grid container spacing={2}>
          {rows.map((row, index) => (
            <Grid item xs={12} key={index}>
              <Grid container spacing={2} alignItems="center">




                <Grid item xs={2.5}>
                  <FormControl fullWidth>
                    {index === 0 && (
                      <FormLabel sx={{ color: 'rgba(0, 0, 0, 0.6)', marginBottom: 0.7,height: '20px' }}>
                        Previous Input
                        </FormLabel>
                    )}
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => toggleStartingState(index)}
                      sx={{
                        backgroundColor: row[0] === '1' ? 'green' : 'white',
                        color: row[0] === '1' ? 'black' : 'black',
                        height: '56px', // Set height to match TextField
                        mt:0
                      }}
                      disabled={!isEditable}
                    >
                      <Typography variant="h6" fontWeight="bold">
                      {row[0]}
                      
                      </Typography>
                    </Button>
                    
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                {index === 0 && (
                      <FormLabel sx={{ color: 'rgba(0, 0, 0, 0.6)', marginBottom: 0,height: '20px'}}>
                        Previous State
                        </FormLabel>
                    )}
                  <TextField
                    //label={index === 0 ? "Starting State" : ""}
                    placeholder="Previous State"
                    value={row[1]}
                    onChange={(e) => handleLetterChange(e, index, 1)}
                    fullWidth
                    sx={{
                      height: '56px', // Match height to button
                      '& .MuiInputBase-root': {
                        height: '100%',
                      },
                    }}
                    disabled={!isEditable}
                  />
                </Grid>

                {/* New input button - opposite of previous input */}
                <Grid item xs={2.5}>
                  <FormControl fullWidth>
                    {index === 0 && (
                      <FormLabel sx={{ color: 'rgba(0, 0, 0, 0.6)', marginBottom: 0.7, height: '20px' }}>
                        New Input
                      </FormLabel>
                    )}
                    <Button
                      variant="outlined"
                      fullWidth
                      //disabled // Non-clickable
                      sx={{
                        backgroundColor: row[0] === '0' ? 'green' : 'white',
                        color: row[0] === '0' ? 'black' : 'black',
                        height: '56px', // Set height to match TextField
                        mt: 0,
                        pointerEvents: 'none'
                      }}
                      disabled={!isEditable}
                    >
                      <Typography variant="h6" fontWeight="bold">
                      {row[0] === '1' ? '0' : '1'}
                      </Typography>
                    </Button>
                  </FormControl>
                </Grid>


                <Grid item xs={3}>
                {index === 0 && (
                      <FormLabel sx={{ color: 'rgba(0, 0, 0, 0.6)', marginBottom: 1,height: '20px'}}>
                        New State
                        </FormLabel>
                    )}
                  <TextField
                    //label={index === 0 ? "State After Input" : ""}
                    placeholder="New State"
                    value={row[2]}
                    onChange={(e) => handleLetterChange(e, index, 2)}
                    fullWidth
                    sx={{
                      height: '56px', // Match height to button
                      '& .MuiInputBase-root': {
                        height: '100%',
                      },
                    }}
                    disabled={!isEditable}
                  />
                </Grid>

                <Grid item xs={0.5}>
                  <IconButton
                    onClick={() => removeExtraState(index)}
                    aria-label="delete"
                    sx={{ color: 'red' }}
                    disabled={!isEditable}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>

              </Grid>

            </Grid>
          ))}
        </Grid>

        <Button
          variant="contained"
          onClick={addExtraState}
          sx={{ marginTop: 2, backgroundColor: parleiiGreen }}
          disabled={!isEditable}
        >
          Add Extra State to Check
        </Button>
      </Paper>
    </Paper>
  );
};

export default StateMachineTable;
