/**
 * Copyright © 2024 Grant D. Powell and Parleii LLC
 *
 * This code is closed source and is intended solely for the use of Grant D. Powell or Parleii LLC. 
 * All rights reserved. No part of this code may be reproduced, distributed, or transmitted 
 * in any form or by any means without the prior written permission of the copyright owners.
 *
 * Grant D. Powell retains primary rights to this code, with Parleii LLC holding rights for internal use and development. 
 * Any commercial use or distribution outside of Parleii LLC requires the explicit permission of Grant D. Powell.
 * 
 * "Parleii LLC" refers to the legal entity and its authorized employees, contractors, and agents.
 *
 * This project includes open-source components licensed under MIT and Apache 2.0 licenses:
 * - @emotion/react (MIT)
 * - @emotion/styled (MIT)
 * - @mui/icons-material (MIT)
 * - @mui/material (MIT)
 * - @testing-library/jest-dom (MIT)
 * - @testing-library/react (MIT)
 * - @types/base-64 (MIT)
 * - @types/react-dom (MIT)
 * - @types/react (MIT)
 * - avrgirl-arduino (MIT)
 * - base-64 (Unlicense)
 * - eslint-config-react-app (MIT)
 * - js-chacha20 (MIT)
 * - react-7-segment-display (MIT)
 * - react-bulb (MIT)
 * - react-dom (MIT)
 * - react-scripts (MIT)
 * - react (MIT)
 * - serialterminal (MIT)
 * - typescript (Apache 2.0)
 * - web-vitals (Apache 2.0)
 *
 * The above licenses apply only to their respective components. 
 * For licensing inquiries, please contact Grant D. Powell at grantdpowell911@gmail.com.
 */
// ProgressBar.tsx
import React from 'react';
import { Box, LinearProgress, Paper, Typography } from '@mui/material';

interface ProgressBarProps {
  currentStep: number;
  totalSteps: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ currentStep, totalSteps }) => {
  const progress = (currentStep / totalSteps) * 100;


  const parleiiGreen = '#4f5024';        // Parleii green color


  return (
    <Box sx={{ width: '80%', mt: 2, mb: 2 }}>
      <Paper elevation={3} sx={{ padding: 1, backgroundColor: 'black' }}>
        <Typography variant="body2" color='white' >{`Progress: ${currentStep} / ${totalSteps}`}</Typography>
        <LinearProgress 
          variant="determinate" 
          value={progress} 
          sx={{
            height: 12, // Increase the height of the progress bar
            '& .MuiLinearProgress-bar': {
              backgroundColor: parleiiGreen, // Set the fill color to Parleii green
            },
          }} 
        />
      </Paper>
    </Box>
  );
};

export default ProgressBar;
