/**
 * Copyright © 2024 Grant D. Powell and Parleii LLC
 *
 * This code is closed source and is intended solely for the use of Grant D. Powell or Parleii LLC. 
 * All rights reserved. No part of this code may be reproduced, distributed, or transmitted 
 * in any form or by any means without the prior written permission of the copyright owners.
 *
 * Grant D. Powell retains primary rights to this code, with Parleii LLC holding rights for internal use and development. 
 * Any commercial use or distribution outside of Parleii LLC requires the explicit permission of Grant D. Powell.
 * 
 * "Parleii LLC" refers to the legal entity and its authorized employees, contractors, and agents.
 *
 * This project includes open-source components licensed under MIT and Apache 2.0 licenses:
 * - @emotion/react (MIT)
 * - @emotion/styled (MIT)
 * - @mui/icons-material (MIT)
 * - @mui/material (MIT)
 * - @testing-library/jest-dom (MIT)
 * - @testing-library/react (MIT)
 * - @types/base-64 (MIT)
 * - @types/react-dom (MIT)
 * - @types/react (MIT)
 * - avrgirl-arduino (MIT)
 * - base-64 (Unlicense)
 * - eslint-config-react-app (MIT)
 * - js-chacha20 (MIT)
 * - react-7-segment-display (MIT)
 * - react-bulb (MIT)
 * - react-dom (MIT)
 * - react-scripts (MIT)
 * - react (MIT)
 * - serialterminal (MIT)
 * - typescript (Apache 2.0)
 * - web-vitals (Apache 2.0)
 *
 * The above licenses apply only to their respective components. 
 * For licensing inquiries, please contact Grant D. Powell at grantdpowell911@gmail.com.
 */
import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { Paper, Typography, Button, Box, Grid , IconButton} from '@mui/material';
import ProgressBar from './Progressbar';
import IOTable from './IOTable';
import StateMachineTable from './StateMachineTable';
import '../styling/TestingPages.css';
import Serial from '../modules/Serial';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import HelpIcon from '@mui/icons-material/Help'; // Import the Help ico

interface StateMachineProps {
  lockButtons: () => void;
  unlockButtons: () => void;
  serial: Serial;
  isClockOn: boolean;
  toggleClock: () => void;
}

const segDictKeys = [
  'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'l', 'n', 'o', 'p', 'q', 
  'r', 's', 't', 'u', 'y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9', 
  '0', '-', ' '
] as const;

type SegDictKey = typeof segDictKeys[number];

const seg_dict: Record<SegDictKey, string> = {
  a: '1110111', b: '0011111', c: '1001110', d: '0111101', e: '1001111', f: '1000111', g: '1111011',
  h: '0010111', i: '0110000', j: '0111000', l: '0001110', n: '0010101', o: '1111110', p: '1100111',
  q: '1110011', r: '0000101', s: '1011011', t: '0001111', u: '0011100', y: '0111011', z: '1101101',
  '1': '0110000', '2': '1101101', '3': '1111001', '4': '0110011', '5': '1011011', '6': '1011111',
  '7': '1110000', '8': '1111111', '9': '1110011', '0': '1111110', '-': '0000001', ' ': '0000000',
};

const Dropdown7Segment: React.FC = () => {
  const [showDictionary, setShowDictionary] = useState(false);

  const toggleDictionary = () => {
    setShowDictionary(!showDictionary);
  };

  return (
    <Box sx={{ width: '370%' , ml:2.5}}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Button
          variant="contained"
          onClick={toggleDictionary}
          sx={{ backgroundColor: '#4f5024', marginBottom: 2, mt: 2 , width: '100%' }}
          endIcon={showDictionary ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        >
          {showDictionary ? 'Hide 7-Segment Dictionary' : 'Show 7-Segment Dictionary'}
        </Button>
      </Box>
      {showDictionary && (
        <Paper elevation={3} className="paper-for-message" sx={{ padding: 2, marginTop: 1 }}>
          <Grid container spacing={3}>
            {Object.entries(seg_dict).map(([key, value]) => (
              <Grid item xs={3} key={key}>
                <Typography variant="body1" fontWeight={'bold'} sx={{ml:0.5}}>
                  '{key}' : {value}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}
    </Box>
  );
};

const StateMachine: React.FC<StateMachineProps> = ({ lockButtons, unlockButtons, serial, isClockOn, toggleClock }) => {
  const [word1, setWord1] = useState('');
  const [word2, setWord2] = useState('');
  const [extraStates, setExtraStates] = useState<string[][]>([]); // Start with no extra states
  const [wordTestResults, setWordTestResults] = useState<string[]>([]);
  const [extraStateTestResults, setExtraStateTestResults] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const cancelRef = useRef(false);
  const [testingMessage, setTestingMessage] = useState('Test Not Running');

  useEffect(() => {
    setWordTestResults(Array(word1.length + word2.length).fill('Pending'));
    setExtraStateTestResults(Array(extraStates.length).fill('Pending'));
    setCurrentStep(0);
  }, [extraStates, word1, word2]);

  const handleWordChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    wordSetter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const value = e.target.value.toLowerCase();
    const filteredValue = value
      .split('')
      .filter((char) => segDictKeys.includes(char as SegDictKey))
      .join('');
    wordSetter(filteredValue);
    setCurrentStep(0);
    setIsRunning(false);
    if (wordSetter === setWord1) {
      setWordTestResults(Array(filteredValue.length + word2.length).fill('Pending'));
    } else {
      setWordTestResults(Array(word1.length + filteredValue.length).fill('Pending'));
    }
  };

  // working as of 
  // 8-30-24
  // 8;09 pm
  const runWordTest = async (word: string, inputSignal: string, startIndex: number, isFirstScan = false) => {
    let firstLetterFound = false;

    console.log(`Starting test for word: ${word} with input signal: ${inputSignal}`);

    // Preliminary check for word2's first letter if it's not the first scan
    if (!isFirstScan && inputSignal === 'x1') {
        if (cancelRef.current) return false; // Exit if canceled
        console.log(`Preliminary check for the first letter of word2: '${word[0]}'`);
        setTestingMessage(`Scanning for Letter '${word[0]}' try ${1}/${1}`);
        const expectedState = seg_dict[word[0] as SegDictKey];
        const response = await sendSerialCommand(inputSignal);
        const [_, outputState] = response.split('|');

        console.log(`Received output state: ${outputState}, Expected: ${expectedState}`);

        if (outputState === expectedState) {
            firstLetterFound = true;
            setTestingMessage(`Testing Letter ${word[0]}`);
            updateWordTestResults(startIndex, 'Pass'); // Update the result for the first letter
            setCurrentStep((prev) => prev + 1); // Update progress here

            // Move on to the next letter if there are more
            if (word.length > 1) {
                word = word.slice(1);
                startIndex++;
            } else {
                console.log(`Word test passed for word: ${word}`);
                return true; // Word 2 test passed immediately
            }
        } else {
            // If the preliminary check fails, initiate scanning
            console.log(`Preliminary check failed, initiating full scan for word2`);
            firstLetterFound = false;
        }
    }

    // Continue scanning if the first letter is not found in the preliminary check or if there are more letters
    for (let i = 0; i < word.length; i++) {
        const expectedState = seg_dict[word[i] as SegDictKey];
        if (!expectedState) throw new Error(`Invalid letter: '${word[i]}'`);

        if (!firstLetterFound) {
            console.log(`Scanning for letter '${word[i]}'`);
            setTestingMessage(`Scanning for Letter '${word[i]}'`);
            let retries = 0;

            while (retries < word.length + 1) {
                setTestingMessage(`Scanning for Letter '${word[i]}' try ${retries + 1}/${word.length + 1}`);
                const response = await sendSerialCommand(inputSignal);
                if (cancelRef.current) return false; // Exit if canceled
                const [_, outputState] = response.split('|');

                console.log(`Received output state: ${outputState}, Expected: ${expectedState}`);

                if (outputState === expectedState) {
                    firstLetterFound = true;
                    updateWordTestResults(startIndex + i, 'Pass'); // Mark first letter as pass
                    setCurrentStep((prev) => prev + 1); // Update progress here
                    break;
                }

                retries++;
                await new Promise((resolve) => setTimeout(resolve, 500));
                if (cancelRef.current) return false; // Exit if canceled
            }

            if (!firstLetterFound) {
                console.error(`Failed to find letter '${word[i]}' after ${retries} retries.`);
                setTestingMessage(`TEST Failed, see tables`);
                updateWordTestResults(startIndex + i, 'Fail');
                return false;  // Stop further testing if failure occurs
            }
        } else {
            // If the first letter was found, move on to the next letter
            console.log(`Testing letter '${word[i]}'`);
            setTestingMessage(`Testing Letter '${word[i]}'`);
            await new Promise((resolve) => setTimeout(resolve, 500)); // Add a 500ms delay here
            const response = await sendSerialCommand(inputSignal);
            if (cancelRef.current) return false; // Exit if canceled
            const [_, outputState] = response.split('|');

            console.log(`Testing letter '${word[i]}': Received output state: ${outputState}, Expected: ${expectedState}`);

            if (outputState === expectedState) {
                updateWordTestResults(startIndex + i, 'Pass');
                setTestingMessage(`Letter '${word[i]}' Passed`);
                setCurrentStep((prev) => prev + 1); // Update progress here
            } else {
                console.error(`Test failed for letter '${word[i]}': Expected ${expectedState}, but received ${outputState}.`);
                updateWordTestResults(startIndex + i, 'Fail');
                setTestingMessage(`TEST Failed, see tables`);
                return false;  // Stop further testing if failure occurs
            }
        }
    }

    console.log(`Word test completed successfully for word: ${word}`);
    return true;
};

  
  
  const updateWordTestResults = (index: number, result: string) => {
    setWordTestResults((prevResults) => {
      const updatedResults = [...prevResults];
      updatedResults[index] = result;
      return updatedResults;
    });
  };

  const updateExtraStateTestResults = (index: number, result: string) => {
    setExtraStateTestResults((prevResults) => {
      const updatedResults = [...prevResults];
      updatedResults[index] = result;
      return updatedResults;
    });
  };

  const addExtraState = () => {
    setExtraStates([...extraStates, ['0', '', '', '']]);
    setExtraStateTestResults((prevResults) => [...prevResults, 'Pending']);
  };

  const sendSerialCommand = async (command: string): Promise<string> => {
    await serial.send(command + '\n');
    return new Promise((resolve) => {
      const handleResponse = (data: string) => {
        if (data.includes('|')) {
          resolve(data);
        }
      };
      const originalOnReceive = serial.onReceive;
      serial.onReceive = (data: string) => {
        handleResponse(data);
        originalOnReceive(data);
      };
    });
  };

  const runExtraStateTest = async () => {
    for (let i = 0; i < extraStates.length; i++) {
      if (cancelRef.current) return false; // Exit if canceled
      const [inputState, startLetter, expectedEndState] = extraStates[i];
      const expectedStartState = seg_dict[startLetter as SegDictKey];
      const inputSignal = inputState === '0' ? 'x0' : 'x1';
      let stateFound = false;
  
      setTestingMessage(`Scanning for First State of Extra State ${i + 1}`);
      
      // Determine the number of retries based on word1 or word2 length plus 1
      const maxRetries = (inputState === '0' ? word1.length + 1 : word2.length + 1);
      let retries = 0;
  
      while (!stateFound && retries < maxRetries) {
        // i want the extra state [][] to return the start letter 
        setTestingMessage(`Scanning for Letter '${startLetter}' try ${retries + 1}/${maxRetries}`); 
        const response = await sendSerialCommand(inputSignal);
        if (cancelRef.current) return false; // Exit if canceled
        const [_, outputState] = response.split('|');
  
        if (outputState === expectedStartState) {
          stateFound = true;
          break;
        }
  
        retries++;
        await new Promise((resolve) => setTimeout(resolve, 500));
        if (cancelRef.current) return false; // Exit if canceled
      }
  
      if (!stateFound) {
        console.error(`Failed to find initial state for Extra State ${i + 1} after ${retries} retries.`);
        setTestingMessage(`TEST Failed, see tables`);
        updateExtraStateTestResults(i, 'Fail');
        return false;  // Stop further testing if failure occurs
      }
  
      setTestingMessage(`Testing Extra State ${i + 1}`);
      const response = await sendSerialCommand(inputSignal === 'x0' ? 'x1' : 'x0');
      if (cancelRef.current) return false; // Exit if canceled
      const [_, outputState] = response.split('|');
  
      if (outputState === seg_dict[expectedEndState as SegDictKey]) {
        updateExtraStateTestResults(i, 'Pass');
        setTestingMessage(`Extra State ${i + 1} Passed`);
      } else {
        console.error(`Test failed for Extra State ${i + 1}: expected ${seg_dict[expectedEndState as SegDictKey]}, but did not receive it.`);
        updateExtraStateTestResults(i, 'Fail');
        setTestingMessage(`TEST Failed, see tables`);
        return false;  // Stop further testing if failure occurs
      }
  
      setCurrentStep((prev) => prev + 1);
    }
  
    return true;
  };

  const handleRunTest = async () => {
    // Validate table
    if (word1 === '' || word2 === '') {
      setTestingMessage('INVALID table');
      return;
    }

    // Validate extra states
    const invalidExtraState = extraStates.some(
      ([inputState, startLetter, expectedEndState]) => 
        startLetter === '' || expectedEndState === ''
    );

    if (invalidExtraState) {
      setTestingMessage('INVALID table');
      return;
    }

    // Clear previous test results and reset the progress
    setWordTestResults(Array(word1.length + word2.length).fill('Pending'));
    setExtraStateTestResults(Array(extraStates.length).fill('Pending'));
    setCurrentStep(0);
    setTestingMessage('Starting New Test...');

    lockButtons();
    setIsRunning(true);
    cancelRef.current = false;

    // Always turn off the clock at the start of the test
    toggleClock();
    await new Promise((resolve) => setTimeout(resolve, 2000)); // Ensure the clock has time to turn off

    try {
      const word1TestResult = await runWordTest(word1, 'x0', 0, true);
      if (!word1TestResult) throw new Error('Word 1 Test Failed'); // Stop if Word 1 test fails

      const word2TestResult = await runWordTest(word2, 'x1', word1.length);
      if (!word2TestResult) throw new Error('Word 2 Test Failed'); // Stop if Word 2 test fails

      setTestingMessage('Testing Extra States');
      const extraStateResult = await runExtraStateTest();
      if (extraStateResult) {
        setTestingMessage('All Tests Passed');
      }
    } catch (error) {
      setTestingMessage('TEST Failed, see tables');
    } finally {
      // Always turn the clock back on at the end of the test
      toggleClock();
      setIsRunning(false);
      unlockButtons();
    }
  };

  const handleStopTest = () => {
    cancelRef.current = true;
    setTimeout(() => {
    setIsRunning(false);
    // Clear everything except for the StateMachineTable
    setWordTestResults([]);
    setExtraStateTestResults([]);
    setCurrentStep(0);
    setTestingMessage('Test Not Running');
    unlockButtons();
    }, 1000);
  };

  const handleResetTest = () => {
    setWord1('');
    setWord2('');
    setExtraStates([]); // Reset to no extra states
    setWordTestResults([]);
    setExtraStateTestResults([]);
    setCurrentStep(0);
    setTestingMessage('Test Not Running');
    
    setTimeout(() => {

      cancelRef.current = false;
      setIsRunning(false);
      unlockButtons();
      }, 1000);
  };

  return (
    <Paper elevation={3} className="main-paper">
      <Paper elevation={3} className="main-paper-title">
      <Typography variant="h6" fontWeight={'bold'} sx={{ml:'20%'}}>
          State Machine
        </Typography>
				{/* Add Help Button */}
				<IconButton
					onClick={() => window.open('https://docs.parleii.com/digilab/one#state-machine', '_blank')}
					sx={{ marginLeft: 'auto', color: 'white' }} // Adjust position and color
				>
					<HelpIcon />
				</IconButton>
      </Paper>

      <Box className="box-for-buttons">
        <Paper elevation={3} className="paper-for-buttons">
          <Button variant="contained" onClick={handleRunTest} disabled={isRunning} sx={{ backgroundColor: '#4f5024', mr: 2 }}>
            Run State Machine
          </Button>
          <Button variant="contained" onClick={handleStopTest} disabled={!isRunning} sx={{ backgroundColor: 'red', mr: 2 }}>
            Stop/Cancel Test
          </Button>
          <Button variant="contained" onClick={handleResetTest} disabled={isRunning} sx={{ backgroundColor: 'blue', mr: 2 }}>
            Reset
          </Button>
        </Paper>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>

      <Paper elevation={3} className="paper-for-message" >
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 , width:'auto'}}>
          <Typography variant="h6" fontWeight={'bold'}>
            {testingMessage}
          </Typography>
        </Box>
      </Paper>

        <ProgressBar currentStep={currentStep} totalSteps={extraStates.length + word1.length + word2.length} />
      </Box>



      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={7.50}>
          <StateMachineTable
            word1={word1}
            setWord1={setWord1}
            word2={word2}
            setWord2={setWord2}
            rows={extraStates} // Pass extra states here
            setRows={setExtraStates}
            addExtraState={addExtraState}
            handleWordChange={handleWordChange}
            isEditable={!isRunning}
          />
        <Grid item xs={3}>
          <Dropdown7Segment />
        </Grid>
        </Grid>

        
        <Grid item xs={2.2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 0 , }}>
              <IOTable
                rows={Array(word1.length + word2.length).fill(['', '', '', '', '', '', ''])} // Create empty rows for word test results
                setRows={() => {}} // No need to update rows for word tests
                editable={false}
                type="result"
                header="Word Tests"
                testResults={wordTestResults}
              />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2.2}>
          <Grid container spacing={2}>
            <Grid item xs={16}>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 0 }}>
              <IOTable
                rows={extraStates}
                setRows={setExtraStates}
                editable={false}
                type="result"
                header="Extra State Tests"
                testResults={extraStateTestResults}
              />
              </Box>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Paper>
  );
};

export default StateMachine;


