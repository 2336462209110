/**
 * Copyright © 2024 Grant D. Powell and Parleii LLC
 *
 * This code is closed source and is intended solely for the use of Grant D. Powell or Parleii LLC. 
 * All rights reserved. No part of this code may be reproduced, distributed, or transmitted 
 * in any form or by any means without the prior written permission of the copyright owners.
 *
 * Grant D. Powell retains primary rights to this code, with Parleii LLC holding rights for internal use and development. 
 * Any commercial use or distribution outside of Parleii LLC requires the explicit permission of Grant D. Powell.
 * 
 * "Parleii LLC" refers to the legal entity and its authorized employees, contractors, and agents.
 *
 * This project includes open-source components licensed under MIT and Apache 2.0 licenses:
 * - @emotion/react (MIT)
 * - @emotion/styled (MIT)
 * - @mui/icons-material (MIT)
 * - @mui/material (MIT)
 * - @testing-library/jest-dom (MIT)
 * - @testing-library/react (MIT)
 * - @types/base-64 (MIT)
 * - @types/react-dom (MIT)
 * - @types/react (MIT)
 * - avrgirl-arduino (MIT)
 * - base-64 (Unlicense)
 * - eslint-config-react-app (MIT)
 * - js-chacha20 (MIT)
 * - react-7-segment-display (MIT)
 * - react-bulb (MIT)
 * - react-dom (MIT)
 * - react-scripts (MIT)
 * - react (MIT)
 * - serialterminal (MIT)
 * - typescript (Apache 2.0)
 * - web-vitals (Apache 2.0)
 *
 * The above licenses apply only to their respective components. 
 * For licensing inquiries, please contact Grant D. Powell at grantdpowell911@gmail.com.
 */
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ConnectButton from './ConnectButton';

interface NavbarProps {
  connectToDevice: () => void;
  disconnectFromDevice: () => void;
  isConnected: boolean;
  connectionStatus: string;
}

const Navbar: React.FC<NavbarProps> = ({
  connectToDevice,
  disconnectFromDevice,
  isConnected,
  connectionStatus,
}) => {
  const parleiiGreen = '#4f5024';
  const parleiiBlue = '#4f46e5';

  const [openFeedback, setOpenFeedback] = useState(false);

  const handleOpenFeedback = () => setOpenFeedback(true);
  const handleCloseFeedback = () => setOpenFeedback(false);

  return (
    <AppBar position="static" sx={{ background: 'black' }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        
        {/* Application title */}
        <Box width="33%">
          <Typography variant="h4" component="div" fontWeight="bold">
            {"Parle"}
            <span style={{ color: parleiiGreen }}>ii</span>
            {" | "}
            <span style={{ color: parleiiBlue }}>Digilab One</span>
          </Typography>
        </Box>

        {/* Connect button to manage connection state */}
        <Box width="33%">
          <ConnectButton
            onConnect={connectToDevice}
            onDisconnect={disconnectFromDevice}
            isConnected={isConnected}
            connectionStatus={connectionStatus}
          />
        </Box>

        {/* Feedback and User guide buttons */}
        <Box width="33%" display="flex" justifyContent="right" alignItems="center" gap={2}>
          <Button
            color="inherit"
            onClick={handleOpenFeedback}
            sx={{ 
              color: parleiiBlue,  
              fontWeight: 'bold', 
              fontSize: '1.25rem',
              textTransform: 'none',
              backgroundColor: '#3326',
              border: '3px solid #4f5024'
            }}
          >
            Feedback
          </Button>
          <Button
            color="inherit"
            component="a"
            href="https://docs.parleii.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ 
              color: parleiiBlue,  
              fontWeight: 'bold', 
              fontSize: '1.25rem',
              textTransform: 'none',
              backgroundColor: '#3326',
              border: '3px solid #4f5024'
            }}
          >
            User Guide
          </Button>
        </Box>
      </Toolbar>

      {/* Feedback Dialog */}
      <Dialog
        open={openFeedback}
        onClose={handleCloseFeedback}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Provide Feedback
          <IconButton
            aria-label="close"
            onClick={handleCloseFeedback}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLScftbSTzHkQI6WSuBU-UUt9ta7FzPCgs9JJUVsFFlvcE78xJw/viewform?embedded=true"
            width="100%"
            height="600"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
          >
            Loading…
          </iframe>
        </DialogContent>
      </Dialog>
    </AppBar>
  );
};

export default Navbar;