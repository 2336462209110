/**
 * Copyright © 2024 Grant D. Powell and Parleii LLC
 *
 * This code is closed source and is intended solely for the use of Grant D. Powell or Parleii LLC. 
 * All rights reserved. No part of this code may be reproduced, distributed, or transmitted 
 * in any form or by any means without the prior written permission of the copyright owners.
 *
 * Grant D. Powell retains primary rights to this code, with Parleii LLC holding rights for internal use and development. 
 * Any commercial use or distribution outside of Parleii LLC requires the explicit permission of Grant D. Powell.
 * 
 * "Parleii LLC" refers to the legal entity and its authorized employees, contractors, and agents.
 *
 * This project includes open-source components licensed under MIT and Apache 2.0 licenses:
 * - @emotion/react (MIT)
 * - @emotion/styled (MIT)
 * - @mui/icons-material (MIT)
 * - @mui/material (MIT)
 * - @testing-library/jest-dom (MIT)
 * - @testing-library/react (MIT)
 * - @types/base-64 (MIT)
 * - @types/react-dom (MIT)
 * - @types/react (MIT)
 * - avrgirl-arduino (MIT)
 * - base-64 (Unlicense)
 * - eslint-config-react-app (MIT)
 * - js-chacha20 (MIT)
 * - react-7-segment-display (MIT)
 * - react-bulb (MIT)
 * - react-dom (MIT)
 * - react-scripts (MIT)
 * - react (MIT)
 * - serialterminal (MIT)
 * - typescript (Apache 2.0)
 * - web-vitals (Apache 2.0)
 *
 * The above licenses apply only to their respective components. 
 * For licensing inquiries, please contact Grant D. Powell at grantdpowell911@gmail.com.
 */
import React from 'react';
import { Button, Box } from '@mui/material';

// Define the props that ConnectButton will accept
interface ConnectButtonProps {
  onConnect: () => void;          // Function to be called when connecting
  onDisconnect: () => void;       // Function to be called when disconnecting
  isConnected: boolean;           // Boolean indicating the connection status
  connectionStatus: string;       // A string representing the connection status text
}

// ConnectButton component renders a button to manage connection state
const ConnectButton: React.FC<ConnectButtonProps> = ({
  onConnect,
  onDisconnect,
  isConnected,
  connectionStatus,
}) => {


  
  return (
    <Box 
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%', // Full width for centering the button
      }}
    >
      <Button
        variant="contained"
        color={isConnected ? 'success' : 'error'}  // Use 'error' color when not connected
        onClick={isConnected ? onDisconnect : onConnect}  // Call the appropriate function based on connection status
      >
        {connectionStatus}  
      </Button>
    </Box>
  );
};

export default ConnectButton;
