/**
 * Copyright © 2024 Grant D. Powell and Parleii LLC
 *
 * This code is closed source and is intended solely for the use of Grant D. Powell or Parleii LLC.
 * All rights reserved. No part of this code may be reproduced, distributed, or transmitted
 * in any form or by any means without the prior written permission of the copyright owners.
 *
 * Grant D. Powell retains primary rights to this code, with Parleii LLC holding rights for internal use and development.
 * Any commercial use or distribution outside of Parleii LLC requires the explicit permission of Grant D. Powell.
 *
 * "Parleii LLC" refers to the legal entity and its authorized employees, contractors, and agents.
 *
 * This project includes open-source components licensed under MIT and Apache 2.0 licenses:
 * - @emotion/react (MIT)
 * - @emotion/styled (MIT)
 * - @mui/icons-material (MIT)
 * - @mui/material (MIT)
 * - @testing-library/jest-dom (MIT)
 * - @testing-library/react (MIT)
 * - @types/base-64 (MIT)
 * - @types/react-dom (MIT)
 * - @types/react (MIT)
 * - avrgirl-arduino (MIT)
 * - base-64 (Unlicense)
 * - eslint-config-react-app (MIT)
 * - js-chacha20 (MIT)
 * - react-7-segment-display (MIT)
 * - react-bulb (MIT)
 * - react-dom (MIT)
 * - react-scripts (MIT)
 * - react (MIT)
 * - serialterminal (MIT)
 * - typescript (Apache 2.0)
 * - web-vitals (Apache 2.0)
 *
 * The above licenses apply only to their respective components.
 * For licensing inquiries, please contact Grant D. Powell at grantdpowell911@gmail.com.
 */
import React, { useState, useEffect, useRef } from 'react';
import IOTable from './IOTable';
import ProgressBar from './Progressbar'; // Import the ProgressBar component
import {
	Button,
	Box,
	TextField,
	Paper,
	IconButton,
	Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import HelpIcon from '@mui/icons-material/Help'; // Import the Help icon
import Serial from '../modules/Serial';
import '../styling/TestingPages.css';

interface CustomTestProps {
	serial: Serial;
	initialRowCount: number;
	lockButtons: () => void;
	unlockButtons: () => void;
}

const CustomTest: React.FC<CustomTestProps> = ({
	serial,
	initialRowCount,
	lockButtons,
	unlockButtons,
}) => {
	const [rowCount, setRowCount] = useState(initialRowCount);
	const [rows, setRows] = useState(
		Array(initialRowCount).fill(Array(7).fill('0')),
	);
	const [outputRows, setOutputRows] = useState(
		Array(initialRowCount).fill(Array(7).fill('0')),
	);
	const [currentStep, setCurrentStep] = useState(0); // Track the current step
	const [isRunning, setIsRunning] = useState(false); // Track the running state
	const cancelRef = useRef(false); // Ref to track cancellation

	const paperOnRoot = '#3326'; // Background color for the main paper component
	const parleiiGreen = '#4f5024'; // Parleii green color

	const rowTimeBetween = 1000;

	const updateRows = (count: number) => {
		setRowCount(count);
		setRows((prevRows) => {
			const currentCount = prevRows.length;
			const colCount = prevRows[0].length;

			if (count > currentCount) {
				const newRows = Array(count - currentCount).fill(
					Array(colCount).fill('0'),
				);
				return [...prevRows, ...newRows];
			} else if (count < currentCount) {
				return prevRows.slice(0, count);
			}
			return prevRows;
		});

		setOutputRows((prevRows) => {
			const currentCount = prevRows.length;
			const colCount = prevRows[0].length;

			if (count > currentCount) {
				const newRows = Array(count - currentCount).fill(
					Array(colCount).fill('0'),
				);
				return [...prevRows, ...newRows];
			} else if (count < currentCount) {
				return prevRows.slice(0, count);
			}
			return prevRows;
		});

		setCurrentStep(0); // Reset the progress
	};

	const handleRunTest = async () => {
		if (!serial) {
			console.error('Serial port not initialized');
			return;
		}
		//console.log('[CustomTest] Running test...');

		setIsRunning(true);
		lockButtons();
		cancelRef.current = false; // Reset the cancel state

		try {
			for (let i = currentStep; i < rows.length; i++) {
				if (cancelRef.current) break; // Stop the loop if cancel is true

				const inputString = rows[i].join('');
				await serial.send(inputString + '\n');

				const receivedData = await new Promise<string>((resolve) => {
					const handleTestData = (data: string) => {
						if (data.includes('|')) {
							resolve(data);
						}
					};

					const originalOnReceive = serial.onReceive;
					const combinedOnReceive = (data: string) => {
						handleTestData(data);
						originalOnReceive(data);
					};

					serial.onReceive = combinedOnReceive;
				});

				if (cancelRef.current) break; // Stop if canceled after sending data

				setOutputRows((prevRows) => {
					const updatedRows = [...prevRows];
					const outputState = receivedData.split('|')[1].split('');
					updatedRows[i] = outputState.length === 7 ? outputState : prevRows[i];
					return updatedRows;
				});

				setCurrentStep(i + 1); // Update the progress
				await new Promise((resolve) => setTimeout(resolve, rowTimeBetween));
			}
		} catch (error) {
			console.error('Error running test:', error);
		} finally {
			setIsRunning(false);
			unlockButtons();
		}
	};

	const handleRun1Row = async (rowIndex: number) => {
		if (!serial) {
			console.error('Serial port not initialized');
			return;
		}
		//console.log(`[CustomTest] Running test for row ${rowIndex + 1}...`);

		setIsRunning(true);
		lockButtons();

		try {
			const inputString = rows[rowIndex].join('');
			await serial.send(inputString + '\n');

			const receivedData = await new Promise<string>((resolve, reject) => {
				const handleTestData = (data: string) => {
					if (data.includes('|')) {
						resolve(data);
					}
				};

				const originalOnReceive = serial.onReceive;
				const combinedOnReceive = (data: string) => {
					try {
						handleTestData(data);
					} catch (err) {
						console.error('Error handling received data:', err);
						reject(err);
					}
					originalOnReceive(data);
				};

				serial.onReceive = combinedOnReceive;
			});

			setOutputRows((prevRows) => {
				const updatedRows = [...prevRows];
				const outputState = receivedData.split('|')[1].split('');
				updatedRows[rowIndex] =
					outputState.length === 7 ? outputState : prevRows[rowIndex];
				return updatedRows;
			});

			setCurrentStep(rowIndex + 1); // Update the progress
			await new Promise((resolve) => setTimeout(resolve, rowTimeBetween));
		} catch (error) {
			console.error(`Error running test for row ${rowIndex + 1}:`, error);
		} finally {
			setIsRunning(false);
			unlockButtons();
		}
	};

	const handleCancel = () => {
		setTimeout(() => {
			cancelRef.current = true; // Set cancel state to true
			setIsRunning(false);
			unlockButtons();
		}, 1000);
	};

	const handleRowCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let count = parseInt(event.target.value, 10);
		if (isNaN(count)) count = 1; // Handle invalid input
		if (count > 128) count = 128; // Constrain to max value
		if (count < 1) count = 1; // Constrain to min value
		updateRows(count);
	};

	const incrementRowCount = () => {
		const count = rowCount + 1;
		updateRows(count);
	};

	const decrementRowCount = () => {
		const count = rowCount > 1 ? rowCount - 1 : 1;
		updateRows(count);
	};

	const handleReset = () => {
		setRowCount(initialRowCount);
		setRows(Array(initialRowCount).fill(Array(7).fill('0')));
		setOutputRows(Array(initialRowCount).fill(Array(7).fill('0')));
		setCurrentStep(0);
		setTimeout(() => {

			setIsRunning(false);
			cancelRef.current = false;
			unlockButtons();
		}, 1000);
	};

	return (
		<Paper elevation={3} className="main-paper">
			<Paper elevation={3} className="main-paper-title">
				<Typography variant="h6" fontWeight={'bold'} sx={{ml:'20%'}}>
					Custom Test
				</Typography>
				{/* Add Help Button */}
				<IconButton
					onClick={() => window.open('https://docs.parleii.com/digilab/one#custom-test', '_blank')}
					sx={{ marginLeft: 'auto', color: 'white' }} // Adjust position and color
				>
					<HelpIcon />
				</IconButton>
			</Paper>

			<Box className="box-for-buttons">
				<Paper elevation={3} className="paper-for-buttons">
					<IconButton onClick={decrementRowCount} disabled={rowCount <= 1}>
						<RemoveIcon />
					</IconButton>
					<TextField
						label="Rows"
						type="number"
						value={rowCount}
						onChange={handleRowCountChange}
						inputProps={{
							min: 1,
							max: 128,
							style: { textAlign: 'center' },
						}}
						sx={{
							width: 100,
							'& input[type=number]': {
								MozAppearance: 'textfield',
								WebkitAppearance: 'none',
								'&::-webkit-outer-spin-button': {
									WebkitAppearance: 'none',
									margin: 0,
								},
								'&::-webkit-inner-spin-button': {
									WebkitAppearance: 'none',
									margin: 0,
								},
							},
						}}
					/>
					<IconButton onClick={incrementRowCount} disabled={rowCount >= 128}>
						<AddIcon />
					</IconButton>
					<Button
						variant="contained"
						onClick={handleRunTest}
						disabled={isRunning}
						sx={{ mtb: 'auto', backgroundColor: parleiiGreen, mr: 2 }}
					>
						Auto Test
					</Button>
					<Button
						variant="contained"
						onClick={() => handleRun1Row(currentStep)} // Pass a function reference to onClick
						disabled={isRunning}
						sx={{ mtb: 'auto', backgroundColor: parleiiGreen, mr: 2 }}
					>
						Next Row
					</Button>

					<Button
						variant="contained"
						onClick={handleCancel} // Handle cancel click
						disabled={!isRunning} // Only enable cancel if a test is running
						sx={{ mtb: 'auto', backgroundColor: 'red', color: 'white', mr: 2 }}
					>
						Pause
					</Button>

					<Button
						variant="contained"
						onClick={handleReset} // Handle reset click
						disabled={isRunning} // Only enable reset if no test is running
						sx={{ mtb: 'auto', backgroundColor: 'blue', color: 'white', mr: 2 }}
					>
						Reset
					</Button>
				</Paper>
			</Box>

			{/* Include the ProgressBar component above the tables */}
			<Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
				<ProgressBar currentStep={currentStep} totalSteps={rowCount} />
			</Box>

			<Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 ,}}>
				<IOTable
					rows={rows}
					setRows={setRows}
					editable={true}
					type="input"
					header="Inputs"
				/>

				<IOTable
					rows={outputRows}
					setRows={setOutputRows}
					editable={false}
					type="output"
					header="Measured Outputs"
				/>
			</Box>
		</Paper>
	);
};

export default CustomTest;
