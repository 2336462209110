/**
 * Copyright © 2024 Grant D. Powell and Parleii LLC
 *
 * This code is closed source and is intended solely for the use of Grant D. Powell or Parleii LLC. 
 * All rights reserved. No part of this code may be reproduced, distributed, or transmitted 
 * in any form or by any means without the prior written permission of the copyright owners.
 *
 * Grant D. Powell retains primary rights to this code, with Parleii LLC holding rights for internal use and development. 
 * Any commercial use or distribution outside of Parleii LLC requires the explicit permission of Grant D. Powell.
 * 
 * "Parleii LLC" refers to the legal entity and its authorized employees, contractors, and agents.
 *
 * This project includes open-source components licensed under MIT and Apache 2.0 licenses:
 * - @emotion/react (MIT)
 * - @emotion/styled (MIT)
 * - @mui/icons-material (MIT)
 * - @mui/material (MIT)
 * - @testing-library/jest-dom (MIT)
 * - @testing-library/react (MIT)
 * - @types/base-64 (MIT)
 * - @types/react-dom (MIT)
 * - @types/react (MIT)
 * - avrgirl-arduino (MIT)
 * - base-64 (Unlicense)
 * - eslint-config-react-app (MIT)
 * - js-chacha20 (MIT)
 * - react-7-segment-display (MIT)
 * - react-bulb (MIT)
 * - react-dom (MIT)
 * - react-scripts (MIT)
 * - react (MIT)
 * - serialterminal (MIT)
 * - typescript (Apache 2.0)
 * - web-vitals (Apache 2.0)
 *
 * The above licenses apply only to their respective components. 
 * For licensing inquiries, please contact Grant D. Powell at grantdpowell911@gmail.com.
 */



export default class Serial {
    onSuccess = () => {}; // Callback for successful connection
    onFail = (errorMsg) => {}; // Callback for failed connection with error message
    onReceive = (data) => {}; // Callback for receiving data
    

    constructor() {
        this.open = false;

        this.textDecoder = undefined;
        this.readableStreamClosed = undefined;
        this.reader = undefined;

        this.port = undefined;

        this.outputStream = undefined;
        this.inputStream = undefined;

        this.baudRate = 9600;
        this.buffer = '';
        this.checkedForUpdate = false;
        this.checkingFirmware = false;
    }

    // Check if the Web Serial API is supported in the browser
    supported() {
        return 'serial' in navigator;
    }

    // Request and open a serial port
// Request and open a serial port
async requestPort() {
    await this.close(); // Ensure any existing connection is closed

    const filters = [
        { usbVendorId: 0x1A86, usbProductId: 0x7523 }, // CH340/CH341 serial converter
        // { usbVendorId: 0x1A86, usbProductId: 0x5523 }, // CH340 (possibly a different configuration or revision)
        // { usbVendorId: 0x1A86, usbProductId: 0x7522 }, // CH340 (another variant)
        // { usbVendorId: 0x1A86, usbProductId: 0x55D4 }, // CH340 (another variant, sometimes seen in USB to UART bridges)
        // { usbVendorId: 0x1A86, usbProductId: 0x0002 }, // CH340-based USB-to-serial adapters, often seen in Arduino clones
        // { usbVendorId: 0x1A86, usbProductId: 0x7520 }, // CH340-based RS232 serial adapter
        // { usbVendorId: 0x1A86, usbProductId: 0x5524 }, // CH340-based USB to RS485/RS422 adapter
        // { usbVendorId: 0x1A86, usbProductId: 0x7525 }, // CH340-based USB to serial, used in some RS485 adapters
        // { usbVendorId: 0x1A86, usbProductId: 0x5520 }, // CH340-based USB to serial, used in other configurations
        // { usbVendorId: 0x1C4F, usbProductId: 0xEA71 }  // Possible SiGma Micro variant
    ];

    const ports = await navigator.serial.getPorts(); // Get existing ports before requesting
    try { 
        const filteredPorts = await navigator.serial.getPorts({filters});

        console.log(`[SERIAL] Available ports:`, ports);

        // const availablePorts = ports.filter(port => filters.some(filter => 
        //     port.getInfo().usbVendorId === filter.usbVendorId &&
        //     port.getInfo().usbProductId === filter.usbProductId
            
        // ));

        console.log(`[SERIAL] Available CH340 ports:`, filteredPorts);

        // If no available ports, prompt user to download drivers
        // NOTE - change to availablePorts.length to filter for ports,
        // 


        // make it .requestPort( { filters })
        // inorder to filter for ch340 works on some machines not on others, 
        // depends on enverimoent - NOTE
        this.port = await navigator.serial.requestPort({filters});
        



       //console.log(`[SERIAL] Port selected:`, this.port);

    } catch (e) {
        this.onFail('Port selection was cancelled or failed');
        

        if (ports.length === 0) {
            this.onFail('No Digilab One Found.\n\nPlease ensure the device is connected.\nIf the issue persists, please install the CH340 Drivers.\n\nTo download the driver, press "OK"');
            const downloadDrivers = window.confirm('No Digilab One Found.\n\nPlease ensure the device is connected.\nIf the issue persists, please install the CH340 Drivers.\n\nTo download the driver, press "OK".\n\n If the issue still persists after installing the drivers:\n\n1. Ensure the driver is installed \n2. Try a different USB port (all) \n3. Try a differnt USB cable \n4. Restart your computer \n5. See if a spare digilab will connect \n6. IF none of the above solve your connection issues \n Contact Digilab Support .');
            if (downloadDrivers) {
                window.open('https://docs.parleii.com/reference/ch340'); // Open the driver download link in a new tab
            }
            return false; // Return false to indicate failure
        }

        return false; // Return false to indicate failure
    }



    return await this.openPort(); // Attempt to open the selected port
}

    // Open the serial port and set up event listeners
    async openPort() {
        if (!this.port) {
            const errorMessage = 'No port selected';
            this.onFail(errorMessage);
            return false; // Return false to indicate failure
        }


        try {
            await this.port.open({ baudRate: this.baudRate }); // Open the port with the specified baud rate
        } catch (e) {
            if (e.message.includes('Failed to open serial port')) {
                this.onFail('[Serial] Port is already in use.');
            } else {
                this.onFail(`Failed to open port: ${e.message}`);
            }
            return false; // Return false to indicate failure
        }

        //console.log.log(`[SERIAL] Connected`);

        this.port.addEventListener('disconnect', () => {
            const errorMessage = `[SERIAL] Disconnected!`;
            this.onFail(errorMessage);
        });

        this.outputStream = this.port.writable;
        this.inputStream = this.port.readable;

        this.onSuccess(); // Trigger success callback
        this.open = true;

        this.read(); // Start reading from the port

        return true; // Return true to indicate success
    }

    // Continuously read data from the input stream
    async read() {
        if (!this.port || !this.port.readable) {
            this.onFail('Port is not open or readable');
            return;
        }

        this.textDecoder = new window.TextDecoderStream();
        this.readableStreamClosed = this.port.readable.pipeTo(this.textDecoder.writable);
        this.reader = this.textDecoder.readable.getReader();

        try {
            while (true && this.open) {
                const { value, done } = await this.reader.read();
                if (done) {
                    break; // Exit loop if reader is done
                }
                if (value) {
                    this.buffer += value;
                    this.processBuffer(); // Process the buffer
                }
            }
        } catch (error) {
            this.onFail('Error reading data');
        } finally {
            await this.close(); // Ensure the port is closed on error
        }
    }

    // Process the buffer to extract complete messages
    processBuffer() {
        const carriageReturnIndex = this.buffer.indexOf('\r');

        if (carriageReturnIndex !== -1) {
            const message = this.buffer.substring(0, carriageReturnIndex);

            const versionPattern = /^[0-9]+v[0-9]+$/i; // Regex for firmware version patterns

            if (versionPattern.test(message.trim())) { // Check if the message is a firmware version
                if (this.checkingFirmware) {
                    this.handleFirmwareVersion(message.trim()); // Handle firmware version message
                } else {
                    this.onReceive(message.trim()); // Pass the message to onReceive callback
                }
            } else if (message.length >= 15) {
                const completeMessage = message.substring(0, 16);
                this.onReceive(completeMessage); // Pass the complete message to onReceive callback

                //console.log.log(`[SERIAL] Received: ${completeMessage}`);
            } else {
                //console.log.warn('[SERIAL] Received incomplete message:', message); // Log incomplete messages
            }

            this.buffer = this.buffer.substring(carriageReturnIndex + 1); // Remove processed message from the buffer
        }
    }

    // Send a string value over the serial connection
    async send(value) {
        if (!this.open) {
            this.onFail('[SERIAL] Cannot send data: Port is not open');
            return;
        }

        if (!this.outputStream) {
            this.onFail('[SERIAL] Cannot send data: Port is not writable');
            return;
        }

        //console.log.log(`[SERIAL] Sent: ${value}`);

        const encoder = new TextEncoder();
        const writer = this.outputStream.getWriter();

        try {
            writer.write(encoder.encode(value)); // Write the encoded string to the output stream
        } catch (error) {
            this.onFail('Failed to send data');
        } finally {
            writer.releaseLock(); // Release the writer's lock
        }
    }

    // Close the serial port
    async close() {
        if (this.open) {
            this.open = false; // Mark the port as closed
    
            if (this.reader) {
                try {
                    await this.reader.cancel();
                } catch (error) {
                    const errorMessage = error?.message || 'Reader cancellation failed';
                    //console.log.error(`[SERIAL] Disconnected - Failed to cancel reader: ${errorMessage}`);
                    this.onFail(`[SERIAL] Disconnected`);
                }
            }
    
            if (this.readableStreamClosed) {
                try {
                    await this.readableStreamClosed;
                } catch (error) {

                    //console.log.error(`[SERIAL] Disconnected - Failed to close readable stream`);
                    this.onFail(`[SERIAL] Disconnected`);
                    // Suppress the error if it's undefined, as it may not be critical
                    if (error?.message) {
                        //console.log.error(`[SERIAL] Disconnected - Failed to close readable stream: ${error.message}`);
                        this.onFail(`[SERIAL] Disconnected`);
                    }
                }
            }
    
            if (this.port) {
                try {
                    await this.port.close();
                } catch (error) {
                    const errorMessage = error?.message || 'Port closure failed';
                    //console.log.error(`[SERIAL] Disconnected - Failed to close port: ${errorMessage}`);
                    this.onFail(`[SERIAL] Disconnected - Failed to close port: ${errorMessage}`);
                }
            }
    
            //console.log.log('[SERIAL] Closed');
        }
    }
    




    // Request the firmware version from the device
    async getFirmwareVersion() {
        this.checkingFirmware = true;
        await this.send('?');
    }

    // Check if the firmware needs to be updated
    async checkForUpdate({ latestVersion }) {
        return new Promise((resolve) => {
            this.originalOnReceive = this.onReceive; // Backup original onReceive callback
            this.onReceive = (data) => {
                const trimmedData = data.trim();
                
                // Regex pattern to identify firmware version strings
                const versionPattern = /^[0-9]+v[0-9]+$/i;
    
                if (versionPattern.test(trimmedData)) {
                    //console.log.log(`Current firmware version: ${trimmedData}`);
                    resolve(trimmedData !== latestVersion); // Resolve true if an update is needed, else false
                    this.checkingFirmware = false;
                    this.restoreOriginalOnReceive(); // Restore original onReceive callback
                } else {
                    //console.log.log(`[SERIAL] Received normal response during version check: ${trimmedData}`);
                    // You might want to handle the normal response differently or just ignore it during the update check.
                    // Optionally, restore the original onReceive immediately:
                    this.originalOnReceive(trimmedData);
                }
            };
            this.getFirmwareVersion(); // Request the firmware version
        });
    }
    

    // Restore the original onReceive callback
    restoreOriginalOnReceive() {
        this.onReceive = this.originalOnReceive;
    }

    // Handle the received firmware version
    handleFirmwareVersion(version) {
        //console.log.log(`[SERIAL] Firmware version received: ${version}`);
        this.onReceive(version); // Call the original onReceive for consistency
        this.checkingFirmware = false; // Clear the flag
    }

    // Set the baud rate for the serial connection
    setBaudRate(newBaudRate) {
        this.baudRate = newBaudRate;
    }
}
