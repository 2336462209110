/**
 * Copyright © 2024 Grant D. Powell and Parleii LLC
 *
 * This code is closed source and is intended solely for the use of Grant D. Powell or Parleii LLC. 
 * All rights reserved. No part of this code may be reproduced, distributed, or transmitted 
 * in any form or by any means without the prior written permission of the copyright owners.
 *
 * Grant D. Powell retains primary rights to this code, with Parleii LLC holding rights for internal use and development. 
 * Any commercial use or distribution outside of Parleii LLC requires the explicit permission of Grant D. Powell.
 * 
 * "Parleii LLC" refers to the legal entity and its authorized employees, contractors, and agents.
 *
 * This project includes open-source components licensed under MIT and Apache 2.0 licenses:
 * - @emotion/react (MIT)
 * - @emotion/styled (MIT)
 * - @mui/icons-material (MIT)
 * - @mui/material (MIT)
 * - @testing-library/jest-dom (MIT)
 * - @testing-library/react (MIT)
 * - @types/base-64 (MIT)
 * - @types/react-dom (MIT)
 * - @types/react (MIT)
 * - avrgirl-arduino (MIT)
 * - base-64 (Unlicense)
 * - eslint-config-react-app (MIT)
 * - js-chacha20 (MIT)
 * - react-7-segment-display (MIT)
 * - react-bulb (MIT)
 * - react-dom (MIT)
 * - react-scripts (MIT)
 * - react (MIT)
 * - serialterminal (MIT)
 * - typescript (Apache 2.0)
 * - web-vitals (Apache 2.0)
 *
 * The above licenses apply only to their respective components. 
 * For licensing inquiries, please contact Grant D. Powell at grantdpowell911@gmail.com.
 */
import React, { useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer, Button, Grid, Menu, MenuItem } from '@mui/material';

interface IOTableProps {
  rows: string[][];
  setRows: React.Dispatch<React.SetStateAction<string[][]>>;
  editable: boolean;
  type: 'input' | 'output' | 'result'; // Added 'result' as an option
  header?: string;
  visible?: boolean; // New prop to control visibility
  testResults?: string[]; // New prop for test results
}

const IOTable: React.FC<IOTableProps> = ({ rows, setRows, editable, type, header, visible = true, testResults }) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const parleiiGreen = '#4f5024';        // Parleii green color
  const parleiiBlue = '#4f46e5';         // Parleii blue color

  const tableTops = '#332c';

  const handleCellChange = (rowIndex: number, colIndex: number) => {
    if (!editable) return;

    setRows(prevRows => {
        const newRows = prevRows.map((row, i) =>
            row.map((cell, j) => {
                if (i === rowIndex && j === colIndex) {
                    if (type === 'input') {
                        // For input tables, only toggle between 0 and 1
                        return cell === '0' ? '1' : '0';
                    } else if (type === 'output') {
                        // For output tables, toggle between 0, 1, and X
                        if (cell === '0') return '1';
                        if (cell === '1') return 'X';
                        if (cell === 'X') return '0';
                    }
                }
                return cell;
            })
        );
        return newRows;
    });
  };

  const updateRowCount = (newCount: number) => {
    setRows(prevRows => {
      const currentCount = prevRows.length;
      const colCount = prevRows[0].length;

      if (newCount > currentCount) {
        const newRows = Array(newCount - currentCount).fill(Array(colCount).fill('0'));
        return [...prevRows, ...newRows];
      } else if (newCount < currentCount) {
        return prevRows.slice(0, newCount);
      }
      return prevRows;
    });
  };

  const fillRowsWithBinary = () => {
    setRows(prevRows => {
      const binaryRows = prevRows.map((_, index) => {
        const binaryValue = index.toString(2).padStart(prevRows[0].length, '0');
        return binaryValue.split('');
      });
      return binaryRows;
    });
  };

  const invertTable = () => {
    setRows(prevRows => {
      const invertedRows = prevRows.map(row => row.map(cell => (cell === '0' ? '1' : '0')));
      return invertedRows;
    });
  };

  const flipTable = () => {
    setRows(prevRows => {
      const flippedRows = prevRows.map(row => [...row].reverse());
      return flippedRows;
    });
  };

  const handleToolsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToolsClose = () => {
    setAnchorEl(null);
  };

  if (!visible) {
    return null; // Don't render anything if the table is not visible
  }

  return (
    <Paper elevation={3} sx={{ padding: 1, backgroundColor: 'black', width: 'auto', m: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {header && (
          <Paper elevation={3}
            sx={{
              width: '100%',
              padding: 0.1,
              backgroundColor: parleiiBlue,
              textAlign: 'center',
              m: 1,
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            <Typography variant='h6' fontWeight={'bold'} sx={{ flex: 1, textAlign: 'center', color: 'black' }}>
              {header}
            </Typography>
            {editable && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleToolsClick}
                  sx={{ position: 'absolute', right: 8 , height: 'auto', padding: '2px' , color: 'black'}}
                >
                  Tools
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleToolsClose}
                >
                  <MenuItem onClick={() => { fillRowsWithBinary(); handleToolsClose(); }}>Fill Rows with Binary</MenuItem>
                  <MenuItem onClick={() => { invertTable(); handleToolsClose(); }}>Invert Table</MenuItem>
                  <MenuItem onClick={() => { flipTable(); handleToolsClose(); }}>Flip Table</MenuItem>
                </Menu>
              </>
            )}
          </Paper>
        )}
        <TableContainer component={Paper} sx={{ width: 'auto' }}>
          <Table sx={{ minWidth: 200 }} size="small">
            <TableHead>
              <TableRow>
                {type !== 'result' && // Don't render the normal columns if it's a result table
                  Array.from({ length: rows[0]?.length || 7 }).map((_, index) => (
                    <TableCell key={index} align="center" sx={{backgroundColor: tableTops}}>
                      <Typography  fontWeight={'bold'}>
                      {type === 'input' ? `S${index + 1}` : `L${index + 1}`}
                      </Typography>
                    </TableCell>
                  ))}
                {testResults && <TableCell align="center" sx={{ minWidth: '50px', backgroundColor: tableTops, color: 'black' }}>
                  <Typography  fontWeight={'bold'}>
                    Pass or Fail
                    </Typography>
                    </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <TableRow key={rowIndex} sx={{ minHeight: '40px' }}>
                  {type !== 'result' && // Don't render the normal columns if it's a result table
                    row.map((cell, colIndex) => (
                      <TableCell
                        key={colIndex}
                        onClick={() => handleCellChange(rowIndex, colIndex)}
                        sx={{
                          cursor: editable ? 'pointer' : 'default',
                          backgroundColor: cell === '1' ? '#3328' : cell === 'X' ? '#4f5024' : '#fff',  // Green for 'X'
                          textAlign: 'center',
                          minWidth: '50px',
                          userSelect: 'none',
                          minHeight: '40px', // Ensures row height consistency
                        }}
                      >
                        <Typography fontSize={13.5} fontWeight={'bold'}>
                        {cell}
                        </Typography>
                      </TableCell>
                    ))}
                  {testResults && (
                    <TableCell align="center" 
                    sx={{ 
                      color: 
                      testResults[rowIndex] === 'Pass' ? parleiiGreen : 
                      testResults[rowIndex] === 'Fail' ? 'black' : 
                      'black',

                      backgroundColor: 
                      testResults[rowIndex] === 'Pass' ? '#00C914' : 
                      testResults[rowIndex] === 'Fail' ? '#C23833' : 
                      'white'
                      }}
                    >
                      <Typography fontSize={13.5} fontWeight={'bold'}>
                      {testResults[rowIndex] || 'Pending'}
                      </Typography>

                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default IOTable;

