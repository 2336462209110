/**
 * Copyright © 2024 Grant D. Powell and Parleii LLC
 *
 * This code is closed source and is intended solely for the use of Grant D. Powell or Parleii LLC. 
 * All rights reserved. No part of this code may be reproduced, distributed, or transmitted 
 * in any form or by any means without the prior written permission of the copyright owners.
 *
 * Grant D. Powell retains primary rights to this code, with Parleii LLC holding rights for internal use and development. 
 * Any commercial use or distribution outside of Parleii LLC requires the explicit permission of Grant D. Powell.
 * 
 * "Parleii LLC" refers to the legal entity and its authorized employees, contractors, and agents.
 *
 * This project includes open-source components licensed under MIT and Apache 2.0 licenses:
 * - @emotion/react (MIT)
 * - @emotion/styled (MIT)
 * - @mui/icons-material (MIT)
 * - @mui/material (MIT)
 * - @testing-library/jest-dom (MIT)
 * - @testing-library/react (MIT)
 * - @types/base-64 (MIT)
 * - @types/react-dom (MIT)
 * - @types/react (MIT)
 * - avrgirl-arduino (MIT)
 * - base-64 (Unlicense)
 * - eslint-config-react-app (MIT)
 * - js-chacha20 (MIT)
 * - react-7-segment-display (MIT)
 * - react-bulb (MIT)
 * - react-dom (MIT)
 * - react-scripts (MIT)
 * - react (MIT)
 * - serialterminal (MIT)
 * - typescript (Apache 2.0)
 * - web-vitals (Apache 2.0)
 *
 * The above licenses apply only to their respective components. 
 * For licensing inquiries, please contact Grant D. Powell at grantdpowell911@gmail.com.
 */
import React, { useState, useRef } from 'react';
import IOTable from './IOTable';
import ProgressBar from './Progressbar';
import { Button, Box, Paper, Typography, Snackbar, Alert , IconButton} from '@mui/material';
import Serial from '../modules/Serial';
import { decryptLab } from '../modules/Crypter';
import '../styling/TestingPages.css';
import HelpIcon from '@mui/icons-material/Help'; // Import the Help ico

interface LabTestingProps {
  lockButtons: () => void;
  unlockButtons: () => void;
  serial: Serial;
}

const LabTesting: React.FC<LabTestingProps> = ({ lockButtons, unlockButtons, serial }) => {
  const [inputRows, setInputRows] = useState<string[][]>([]);
  const [outputRows, setOutputRows] = useState<string[][]>([]);
  const [measuredOutputRows, setMeasuredOutputRows] = useState<string[][]>([]);
  const [testResults, setTestResults] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loadedLab, setLoadedLab] = useState<string | null>(null);

  const [isRunning, setIsRunning] = useState(false);
  const cancelRef = useRef(false); // Ref to track cancellation

  const paperOnRoot = '#3326';           // Background color for the main paper component
  const parleiiGreen = '#4f5024';        // Parleii green color
  const parleiiBlue = '#4f46e5';         // Parleii blue color'

  const rowTimeBetween = 1000;           // Time between rows in milliseconds
  // 929
  // 864


  const resetInputValue = (inputRef: React.RefObject<HTMLInputElement>) => {
    if (inputRef.current) {
        inputRef.current.value = '';
    }
  };

  const handleLabUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setLoadedLab(file?.name as string);
    resetInputValue(fileInputRef);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const labData = e.target?.result as string;

        if (!labData) {
          const errorMsg = 'No data found in the uploaded file.';
         //console.error(errorMsg);
          setError(errorMsg);
          setSnackbarOpen(true);
          return;
        }

        let dataToDecrypt = labData;
        if (labData.includes('!')) {
          dataToDecrypt = labData.split('!')[1]; // Skip the password part
        }

        const [nonce, data] = dataToDecrypt.split(/\r?\n/);

        if (!nonce || !data) {
          const errorMsg = 'Failed to split nonce and data.';
         //console.error(errorMsg);
          setError(errorMsg);
          setSnackbarOpen(true);
          handleReset();
          return;
        }

        const decryptedLab = decryptLab(nonce, data);

        if (decryptedLab) {
         //console.log('Decrypted Lab:', decryptedLab);

          const lines = decryptedLab.split('\n').map(line => line.trim());
          const inputs = lines.map(line => line.split('|')[0].replace(/^,|,$/g, '')).join('\n');
          const outputs = lines.map(line => line.split('|')[1].replace(/^,|,$/g, '')).join('\n');

          if (!inputs || !outputs) {
            const errorMsg = 'Failed to split inputs and outputs.';
           //console.error(errorMsg);
            setError(errorMsg);
            setSnackbarOpen(true);
            return;
          }

          const inputArray = inputs.split('\n').map(row => row.trim().split(','));
          const outputArray = outputs.split('\n').map(row => row.trim().split(','));

          setInputRows(inputArray);
          setOutputRows(outputArray);
          setMeasuredOutputRows(Array(inputArray.length).fill(Array(7).fill('0')));
          setTestResults(Array(inputArray.length).fill('Pending')); // Initialize with "Pending"
          setCurrentStep(0); // Reset the progress
        } else {
          const errorMsg = 'Failed to decrypt lab data.';
         //console.error(errorMsg);
          setError(errorMsg);
          setSnackbarOpen(true);
        }
      };
      reader.readAsText(file);
    }
  };

  const runRowTest = async (rowIndex: number) => {
    const inputRow = inputRows[rowIndex].join('');

    await serial.send(inputRow + '\n');

    const receivedData = await new Promise<string>((resolve) => {
      const handleTestData = (data: string) => {
        if (data.includes('|')) {
          resolve(data);
        }
      };

      const originalOnReceive = serial.onReceive;
      const combinedOnReceive = (data: string) => {
        handleTestData(data);
        originalOnReceive(data);
      };

      serial.onReceive = combinedOnReceive;
    });

    const [actualInputRow, actualOutputRow] = receivedData.split('|');
    const measuredOutputs = actualOutputRow.split('');

    const expectedOutputRow = outputRows[rowIndex].join('');

    setMeasuredOutputRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex] = measuredOutputs;
      return updatedRows;
    });

    
    let testResult = 'Pass';
    
    for (let i = 0; i < expectedOutputRow.length; i++) {
        const expected = expectedOutputRow[i];
        const measured = measuredOutputs[i];
        
        // If the expected is 'X', ignore the measured value
        if (expected !== 'X' && expected !== measured) {
            testResult = 'Fail';
            break;
        }
    }
    
    setTestResults((prevResults) => {
      const updatedResults = [...prevResults];
      updatedResults[rowIndex] = testResult; // Update the specific row's result
      return updatedResults;
    });

    setCurrentStep(rowIndex + 1); // Move to the next step
  };

  const handleRunLabTest = async () => {
    if (!serial) {
      const errorMsg = 'Serial port not initialized';
     //console.error(errorMsg);
      setError(errorMsg);
      setSnackbarOpen(true);
      return;
    }

    lockButtons();
    setIsRunning(true);
    cancelRef.current = false; // Reset the cancel state

    try {
      for (let i = currentStep; i < inputRows.length; i++) {
        if (cancelRef.current) {
          setIsRunning(false);
          break;
         } // Stop the loop if cancel is true
        await runRowTest(i);
        await new Promise(resolve => setTimeout(resolve, rowTimeBetween)); // Delay between rows
      }

     //console.log('Lab test completed.');
    } catch (error) {
      const errorMsg = 'Error running lab test.';
     //console.error(errorMsg, error);
      setError(errorMsg);
      setSnackbarOpen(true);
    } finally {
      setIsRunning(false);
      unlockButtons();
    }
  };

  const handleRun1Row = async () => {
    if (!serial) {
      const errorMsg = 'Serial port not initialized';
     //console.error(errorMsg);
      setError(errorMsg);
      setSnackbarOpen(true);
      return;
    }

    if (currentStep >= inputRows.length) return; // Prevent running beyond the available rows

    lockButtons();
    setIsRunning(true);

    try {
      await runRowTest(currentStep);
      await new Promise(resolve => setTimeout(resolve, rowTimeBetween)); // Delay between rows
    } catch (error) {
      const errorMsg = `Error running lab test for row ${currentStep + 1}.`;
     //console.error(errorMsg, error);
      setError(errorMsg);
      setSnackbarOpen(true);
    } finally {
      setIsRunning(false);
      unlockButtons();
    }
  };

  const handleCancel = () => {
    setTimeout(() => {
    cancelRef.current = true; // Set cancel state to true
    //setIsRunning(false);
    unlockButtons();
    }, 1000);
  };

  const handleReset = () => {
    setInputRows([]);
    setOutputRows([]);
    setMeasuredOutputRows([]);
    setTestResults([]);
    setCurrentStep(0);
    setTimeout(() => {

    setIsRunning(false);
    cancelRef.current = false;
    setLoadedLab(null); // Clear the loaded lab file
    resetInputValue(fileInputRef); // Reset the file input value
    unlockButtons();
    }, 1000);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setError(null);
  };

  const handleRestart = () => {
    // Reset measured output rows with '0's
    setMeasuredOutputRows(Array(inputRows.length).fill(Array(7).fill('0')));
    setTestResults(Array(inputRows.length).fill('Pending')); // Reinitialize test results to "Pending"
    setCurrentStep(0);         // Reset progress
    cancelRef.current = false; // Ensure the cancel state is reset
    setIsRunning(false);
    setTimeout(() => {
      
      unlockButtons();
    }, 1000);
  };
  return (
    <Paper elevation={3} className='main-paper'>

      <Paper elevation={3} className='main-paper-title'>
      <Typography variant="h6" fontWeight={'bold'} sx={{ml:'20%'}}>
          Lab Testing
          </Typography>
				{/* Add Help Button */}
				<IconButton
					onClick={() => window.open('https://docs.parleii.com/digilab/one#lab-testing', '_blank')}
					sx={{ marginLeft: 'auto', color: 'white' }} // Adjust position and color
				>
					<HelpIcon />
				</IconButton>
      </Paper>


      <Box className='box-for-buttons'>
      <Paper 
        elevation={3} className='paper-for-buttons'
      >
        
          <Button variant="contained" onClick={() => fileInputRef.current?.click()} disabled={loadedLab !== null} sx={{ mtb: 'auto' , backgroundColor: parleiiBlue , mr:2}}>
            {loadedLab && (
              <Typography fontWeight={'bold'}>
                loaded Lab: {loadedLab.replace(/(\.digilab|_editable\.digilab)/g, '')}
              </Typography>
            )}
            {!loadedLab && (
              <Typography fontWeight={'bold'}>
                Upload Lab File
              </Typography>
            )}
          </Button>
          <Button variant="contained" onClick={handleRunLabTest} disabled={isRunning || !loadedLab} sx={{ mtb: 'auto' , backgroundColor: parleiiGreen, mr:2 }}>
            Auto Test
          </Button>
          <Button variant="contained" onClick={handleRun1Row} disabled={isRunning || !loadedLab} sx={{ mtb: 'auto', backgroundColor: parleiiGreen , mr:2}}>
            Next Row
          </Button>
          <Button 
            variant="contained" 
            onClick={handleCancel} 
            disabled={!isRunning} 
            sx={{ mtb: 'auto', backgroundColor: 'red', color: 'white' , mr:2}}
          >
            Pause
          </Button>
          <Button 
            variant="contained" 
            onClick={handleRestart} 
            disabled={isRunning} 
            sx={{ mtb: 'auto', backgroundColor: 'blue', color: 'white', mr: 2 }}
          >
            Restart
          </Button>

          <Button 
            variant="contained" 
            onClick={handleReset} 
            disabled={isRunning } 
            sx={{ mtb: 'auto', backgroundColor: 'blue', color: 'white' , mr:2 }}
          >
            Reset
          </Button>

       
      </Paper>
      </Box>




      <input type="file" accept=".digilab" onChange={handleLabUpload} style={{ display: 'none' }} ref={fileInputRef} />




      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
        <ProgressBar currentStep={currentStep} totalSteps={inputRows.length} />
      </Box>




      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 0 }}>
        <IOTable rows={inputRows} setRows={setInputRows} editable={false} type="input" header='Inputs' visible={true} />
        <IOTable rows={outputRows} setRows={setOutputRows} editable={false} type="output" header='Expected Outputs' visible={false} />
        <IOTable rows={measuredOutputRows} setRows={setMeasuredOutputRows} editable={false} type="output" header='Measured Outputs' visible={true}/>
        <IOTable rows={outputRows} setRows={setOutputRows} editable={false} type="result" header='Test Results' visible={true} testResults={testResults} />
      </Box>




      {/* Snackbar Component */}
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default LabTesting;
