/**
 * Copyright © 2024 Grant D. Powell and Parleii LLC
 *
 * This code is closed source and is intended solely for the use of Grant D. Powell or Parleii LLC. 
 * All rights reserved. No part of this code may be reproduced, distributed, or transmitted 
 * in any form or by any means without the prior written permission of the copyright owners.
 *
 * Grant D. Powell retains primary rights to this code, with Parleii LLC holding rights for internal use and development. 
 * Any commercial use or distribution outside of Parleii LLC requires the explicit permission of Grant D. Powell.
 * 
 * "Parleii LLC" refers to the legal entity and its authorized employees, contractors, and agents.
 *
 * This project includes open-source components licensed under MIT and Apache 2.0 licenses:
 * - @emotion/react (MIT)
 * - @emotion/styled (MIT)
 * - @mui/icons-material (MIT)
 * - @mui/material (MIT)
 * - @testing-library/jest-dom (MIT)
 * - @testing-library/react (MIT)
 * - @types/base-64 (MIT)
 * - @types/react-dom (MIT)
 * - @types/react (MIT)
 * - avrgirl-arduino (MIT)
 * - base-64 (Unlicense)
 * - eslint-config-react-app (MIT)
 * - js-chacha20 (MIT)
 * - react-7-segment-display (MIT)
 * - react-bulb (MIT)
 * - react-dom (MIT)
 * - react-scripts (MIT)
 * - react (MIT)
 * - serialterminal (MIT)
 * - typescript (Apache 2.0)
 * - web-vitals (Apache 2.0)
 *
 * The above licenses apply only to their respective components. 
 * For licensing inquiries, please contact Grant D. Powell at grantdpowell911@gmail.com.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Bulb Component
 *
 * This component renders a bulb-like visual element using SVG. The bulb can display a color,
 * apply a glow effect, and includes gradient overlays to simulate lighting and shadow effects.
 *
 * Props:
 * - size: The radius of the bulb. The actual rendered bulb will be twice this size in both width and height.
 * - color: The base color of the bulb. Typically 'red' when the bulb is on, and a darker color (e.g., '#333') when off.
 * - style: Additional CSS styles applied to the SVG container.
 * - glow: Boolean that determines whether to apply a glowing effect around the bulb.
 */
const Bulb = ({ size = 40, color = 'red', style = Object.freeze({}), glow = false }) => (
  <svg
    style={{
      ...style,
      // Set the width and height of the SVG element to be twice the size of the bulb's radius
      width: size * 2,
      height: size * 2,
      // If glow is true, apply a drop shadow around the bulb with the same color as the bulb
      filter: glow ? `drop-shadow(0 0 10px ${color})` : 'none',
    }}
  >
    <defs>
      {/* 
        Gradients Definitions:
        - These are reusable gradients that can be applied to any SVG element within this component.
        - They are defined once and referenced by their IDs ('grad1' and 'grad2') later in the component.
      */}

      {/* 
        Gradient 1 (grad1): Simulates a light source highlighting the bulb from the top-left corner.
        - This is a radial gradient, which means it starts from the center and radiates outwards.
        - The gradient starts with a white color at the center (to simulate a light reflection) 
          and gradually transitions to transparent over the bulb's base color.
      */}
      <radialGradient
        id="grad1"
        cx="0%"  // X-axis coordinate of the gradient's center
        cy="0%"  // Y-axis coordinate of the gradient's center
        r="100%" // Radius of the gradient
        fx="30%" // X-axis focal point of the gradient, creating an offset effect
        fy="60%" // Y-axis focal point of the gradient, creating an offset effect
      >
        <stop
          offset="0%"
          style={{
            stopColor: 'white',   // Start with white color at the focal point
            stopOpacity: 0.35,     // 40% opacity for a soft light effect
          }}
        />
        <stop
          offset="60%"
          style={{
            stopColor: color,     // Transition to the bulb's base color towards the edges
            stopOpacity: 0,       // Fully transparent at the outer edge of the gradient
          }}
        />
      </radialGradient>

      {/* 
        Gradient 2 (grad2): Simulates shadow and depth on the bulb.
        - This is another radial gradient, but its center is offset further out, creating a shadow effect.
        - The gradient starts with black (shadow) and transitions to a slightly transparent version of the base color.
      */}
      <radialGradient
        id="grad2"
        cx="950%"  // X-axis coordinate of the gradient's center, placed outside the bulb to create an offset shadow
        cy="900%"  // Y-axis coordinate of the gradient's center
        r="900%"   // Radius of the gradient, extending beyond the bulb's edge
        fx="00%"  // X-axis focal point, creating the shadow effect
        fy="0%"  // Y-axis focal point
      >
        <stop
          offset="0%"
          style={{
            stopColor: 'black',   // Start with black color at the focal point for shadow
            stopOpacity: 0.15,    // 25% opacity for a subtle shadow effect
          }}
        />
        <stop
          offset="100%"
          style={{
            stopColor: color,     // Transition to the bulb's base color, blending with the shadow
            stopOpacity: 0.1,     // Slightly transparent at the outer edge to create a smooth transition
          }}
        />
      </radialGradient>
    </defs>

    {/* 
      The main circle element representing the bulb.
      - The bulb is drawn as a circle centered at (size, size) with a radius of 'size'.
      - The fill attribute uses the 'color' prop, which will typically be 'red' when the bulb is on.
    */}
    <circle
      cx={size}  // X-coordinate of the circle's center
      cy={size}  // Y-coordinate of the circle's center
      r={size}   // Radius of the circle, determined by the 'size' prop
      fill={color}  // Base color of the bulb
    />

    {/* 
      Apply the first gradient (grad1) to the bulb.
      - This overlay creates a highlighted effect, making the bulb look more three-dimensional.
    */}
    {/* <circle
      cx={size}
      cy={size}
      r={size}
      fill="url(#grad1)"  // Fill with the first gradient
    /> */}

    {/* 
      Apply the second gradient (grad2) to the bulb.
      - This overlay creates a shadow effect, adding depth to the bulb.
    */}
    {/* <circle
      cx={size}
      cy={size}
      r={size}
      fill="url(#grad2)"  // Fill with the second gradient
    /> */}
  </svg>
);

Bulb.propTypes = {
  style: PropTypes.shape({}), // Additional styles passed to the SVG container
  size: PropTypes.number, // The radius of the bulb
  color: PropTypes.string, // The base color of the bulb (e.g., 'red' or '#333')
  glow: PropTypes.bool, // Whether the bulb should glow (applies a drop shadow if true)
};

export default Bulb;
