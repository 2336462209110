/**
 * Copyright © 2024 Grant D. Powell and Parleii LLC
 *
 * This code is closed source and is intended solely for the use of Grant D. Powell or Parleii LLC. 
 * All rights reserved. No part of this code may be reproduced, distributed, or transmitted 
 * in any form or by any means without the prior written permission of the copyright owners.
 *
 * Grant D. Powell retains primary rights to this code, with Parleii LLC holding rights for internal use and development. 
 * Any commercial use or distribution outside of Parleii LLC requires the explicit permission of Grant D. Powell.
 * 
 * "Parleii LLC" refers to the legal entity and its authorized employees, contractors, and agents.
 *
 * This project includes open-source components licensed under MIT and Apache 2.0 licenses:
 * - @emotion/react (MIT)
 * - @emotion/styled (MIT)
 * - @mui/icons-material (MIT)
 * - @mui/material (MIT)
 * - @testing-library/jest-dom (MIT)
 * - @testing-library/react (MIT)
 * - @types/base-64 (MIT)
 * - @types/react-dom (MIT)
 * - @types/react (MIT)
 * - avrgirl-arduino (MIT)
 * - base-64 (Unlicense)
 * - eslint-config-react-app (MIT)
 * - js-chacha20 (MIT)
 * - react-7-segment-display (MIT)
 * - react-bulb (MIT)
 * - react-dom (MIT)
 * - react-scripts (MIT)
 * - react (MIT)
 * - serialterminal (MIT)
 * - typescript (Apache 2.0)
 * - web-vitals (Apache 2.0)
 *
 * The above licenses apply only to their respective components. 
 * For licensing inquiries, please contact Grant D. Powell at grantdpowell911@gmail.com.
 */
import AvrgirlArduino from 'avrgirl-arduino';
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Typography } from '@mui/material';

// AutoUpdater component handles the process of updating firmware on a connected device
const AutoUpdater = ({ serial, firmwareUrl, latestVersion, onComplete, onDeny }) => {
    const [open, setOpen] = useState(true);    // State to control dialog visibility
    const [loading, setLoading] = useState(false); // State to track loading progress
    const [message, setMessage] = useState('');    // State to display status messages
    const [updateComplete, setUpdateComplete] = useState(false); // State to track completion of the update

    // Handle the closing of the dialog
    const handleClose = () => {
        localStorage.setItem('updateDenied', 'true'); // Track the denial in local storage
        setOpen(false); // Close the dialog
        onDeny();  // Call the onDeny callback to notify parent component
    };

    const handleCloseUpdateComplete = () => {
        localStorage.removeItem('updateDenied'); // Clear the denial flag after a successful update
        setOpen(false); // Close the dialog
        onComplete(); // Call the onComplete callback to notify parent component
        window.location.reload();
    };

// Start the firmware update process
const startUpdate = async () => {
    setLoading(true); // Start loading
    try {
        // Check if the serial port is already open, and close it if necessary
        if (serial.port && serial.open) {
           //console.log('[AutoUpdate] Closing existing port...');
            await serial.close();
        }

        // Request the specific CH340 serial port
        const filters = [
            { usbVendorId: 0x1A86, usbProductId: 0x7523 }, // CH340/CH341 serial converter
            // { usbVendorId: 0x1A86, usbProductId: 0x5523 }, // CH340 (possibly a different configuration or revision)
            // { usbVendorId: 0x1A86, usbProductId: 0x7522 }, // CH340 (another variant)
            // { usbVendorId: 0x1A86, usbProductId: 0x55D4 }, // CH340 (another variant, sometimes seen in USB to UART bridges)
            // { usbVendorId: 0x1A86, usbProductId: 0x0002 }, // CH340-based USB-to-serial adapters, often seen in Arduino clones
            // { usbVendorId: 0x1A86, usbProductId: 0x7520 }, // CH340-based RS232 serial adapter
            // { usbVendorId: 0x1A86, usbProductId: 0x5524 }, // CH340-based USB to RS485/RS422 adapter
            // { usbVendorId: 0x1A86, usbProductId: 0x7525 }, // CH340-based USB to serial, used in some RS485 adapters
            // { usbVendorId: 0x1A86, usbProductId: 0x5520 }, // CH340-based USB to serial, used in other configurations
            // { usbVendorId: 0x1C4F, usbProductId: 0xEA71 }  // Possible SiGma Micro variant
        ];

        // Select the appropriate port
        // const ports = await navigator.serial.getPorts();
        // const selectedPort = ports.find(port => filters.some(filter => {
        //     return port.getInfo().usbVendorId === filter.usbVendorId && port.getInfo().usbProductId === filter.usbProductId;
        // }));

        // if (!selectedPort) {
        //     throw new Error('No CH340 device found. Please ensure the device is connected.');
        // }

        // // **Check if the port is already open**
        // if (selectedPort.open) {
        //    //console.log('[AutoUpdate] Port is already open. Skipping open step.');
        // } else {
        //    //console.log('[AutoUpdate] Opening the selected port...');
        //     await selectedPort.open({ baudRate: 9600 });
        // }

        const avrgirl = new AvrgirlArduino({
            board: 'nano (new bootloader)', // Specify the board type
            debug: true, // Enable debug mode
            //serialPort: selectedPort, // Pass the serial port
        });

        // Fetch the hex file from the provided firmware URL
       //console.log(`[AutoUpdate] Fetching firmware from ${firmwareUrl}`);
        const response = await fetch(firmwareUrl);
        if (!response.ok) {
            throw new Error(`Failed to fetch firmware: ${response.statusText}`);
        }

        const filecontents = await response.arrayBuffer(); // Read the response as an array buffer

        // Flash the firmware using AvrgirlArduino
        avrgirl.flash(filecontents, (error) => {
            if (error) {
               //console.error('Failed to update firmware:', error);
                setMessage('Firmware update failed.'); // Update message on failure
            } else {
                //console.log('Firmware update complete');
                setUpdateComplete(true); // Update the state to indicate completion
                setMessage('Firmware update complete. Page will auto reload.');
                localStorage.removeItem('updateDenied'); // Clear the denial flag after a successful update
                //alert('Firmware update complete site will reload.');
                // Wait for the user to press OK before reloading

            }
            setLoading(false); // Stop loading after the update process
        });
    } catch (error) {
       //console.error('Failed to update firmware:', error);
        setMessage('Firmware update failed.'); // Display error message
        setLoading(false); // Stop loading
    } finally {
        serial.restoreOriginalOnReceive(); // Restore the original onReceive callback
    }
};

    
    
    
    

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Firmware Update</DialogTitle>
            <DialogContent>
                {loading ? (
                    <CircularProgress /> // Show a loading spinner during the update
                ) : (
                    <Typography>
                        {message || `A new firmware version (${latestVersion}) is available. Would you like to update now?`}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                {!loading && !message && (
                    <>
                        <Button onClick={handleClose}>No</Button> {/* Deny the update */}
                        <Button onClick={startUpdate} autoFocus>
                            Yes
                        </Button>
                    </>
                )}
                {/* {message && (
                    <Button onClick={handleClose}>
                        Close 
                    </Button>

                )} */}
                {message && updateComplete && (
                    <Button onClick={handleCloseUpdateComplete}>
                        Close 
                    </Button>
              )}
            </DialogActions>
        </Dialog>
    );
};

export default AutoUpdater;
