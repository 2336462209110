/**
 * Copyright © 2024 Grant D. Powell and Parleii LLC
 *
 * This code is closed source and is intended solely for the use of Grant D. Powell or Parleii LLC. 
 * All rights reserved. No part of this code may be reproduced, distributed, or transmitted 
 * in any form or by any means without the prior written permission of the copyright owners.
 *
 * Grant D. Powell retains primary rights to this code, with Parleii LLC holding rights for internal use and development. 
 * Any commercial use or distribution outside of Parleii LLC requires the explicit permission of Grant D. Powell.
 * 
 * "Parleii LLC" refers to the legal entity and its authorized employees, contractors, and agents.
 *
 * This project includes open-source components licensed under MIT and Apache 2.0 licenses:
 * - @emotion/react (MIT)
 * - @emotion/styled (MIT)
 * - @mui/icons-material (MIT)
 * - @mui/material (MIT)
 * - @testing-library/jest-dom (MIT)
 * - @testing-library/react (MIT)
 * - @types/base-64 (MIT)
 * - @types/react-dom (MIT)
 * - @types/react (MIT)
 * - avrgirl-arduino (MIT)
 * - base-64 (Unlicense)
 * - eslint-config-react-app (MIT)
 * - js-chacha20 (MIT)
 * - react-7-segment-display (MIT)
 * - react-bulb (MIT)
 * - react-dom (MIT)
 * - react-scripts (MIT)
 * - react (MIT)
 * - serialterminal (MIT)
 * - typescript (Apache 2.0)
 * - web-vitals (Apache 2.0)
 *
 * The above licenses apply only to their respective components. 
 * For licensing inquiries, please contact Grant D. Powell at grantdpowell911@gmail.com.
 */
import JSChaCha20 from 'js-chacha20';
import { encode as base64Encode, decode as base64Decode } from 'base-64';

const key = new Uint8Array([
    0x24, 0x38, 0x31, 0x30, 0x46, 0x79, 0x35, 0x16,
    0x90, 0x12, 0x43, 0x83, 0x13, 0x04, 0x67, 0x93,
    0x51, 0x69, 0x01, 0x33, 0x65, 0x67, 0x60, 0x57,
    0x85, 0x03, 0x59, 0x99, 0x15, 0x29, 0x58, 0x02
]);

export const encryptLab = (data: string, secondaryPassword?: string) => {
    const nonce = new Uint8Array(12);
    window.crypto.getRandomValues(nonce);

    const message = new TextEncoder().encode(secondaryPassword ? `${secondaryPassword}!${data}` : data);
    const chacha = new JSChaCha20(key, nonce);
    const encrypted = chacha.encrypt(message);

    const encodedNonce = base64Encode(String.fromCharCode(...nonce));
    const encodedData = base64Encode(String.fromCharCode(...encrypted));

    return {
        nonce: encodedNonce,
        data: encodedData
    };
};

export const decryptLab = (nonce: string, data: string, inputPassword?: string): string | null => {
    try {
        const decodedNonce = Uint8Array.from(base64Decode(nonce).split('').map(c => c.charCodeAt(0)));
        const encryptedData = Uint8Array.from(base64Decode(data).split('').map(c => c.charCodeAt(0)));

        if (decodedNonce.length !== 12) {
            throw new Error('Nonce should be 12 bytes array!');
        }

        const chacha = new JSChaCha20(key, decodedNonce);
        const decrypted = chacha.decrypt(encryptedData);
        const decryptedString = new TextDecoder().decode(decrypted);

        if (inputPassword) {
            const [password, ...labData] = decryptedString.split('!');
            if (password === inputPassword) {
                return labData.join('!');
            } else {
               //console.error('Invalid secondary password');
                return null;
            }
        }

        return decryptedString;
    } catch (error) {
       //console.error('Error during decryption:', error);
        return null;
    }
};
