/**
 * Copyright © 2024 Grant D. Powell and Parleii LLC
 *
 * This code is closed source and is intended solely for the use of Grant D. Powell or Parleii LLC. 
 * All rights reserved. No part of this code may be reproduced, distributed, or transmitted 
 * in any form or by any means without the prior written permission of the copyright owners.
 *
 * Grant D. Powell retains primary rights to this code, with Parleii LLC holding rights for internal use and development. 
 * Any commercial use or distribution outside of Parleii LLC requires the explicit permission of Grant D. Powell.
 * 
 * "Parleii LLC" refers to the legal entity and its authorized employees, contractors, and agents.
 *
 * This project includes open-source components licensed under MIT and Apache 2.0 licenses:
 * - @emotion/react (MIT)
 * - @emotion/styled (MIT)
 * - @mui/icons-material (MIT)
 * - @mui/material (MIT)
 * - @testing-library/jest-dom (MIT)
 * - @testing-library/react (MIT)
 * - @types/base-64 (MIT)
 * - @types/react-dom (MIT)
 * - @types/react (MIT)
 * - avrgirl-arduino (MIT)
 * - base-64 (Unlicense)
 * - eslint-config-react-app (MIT)
 * - js-chacha20 (MIT)
 * - react-7-segment-display (MIT)
 * - react-bulb (MIT)
 * - react-dom (MIT)
 * - react-scripts (MIT)
 * - react (MIT)
 * - serialterminal (MIT)
 * - typescript (Apache 2.0)
 * - web-vitals (Apache 2.0)
 *
 * The above licenses apply only to their respective components. 
 * For licensing inquiries, please contact Grant D. Powell at grantdpowell911@gmail.com.
 */
import React from 'react';
import { segmentStyle, skewedSegmentStyle } from '../utils/segmentStyle';

interface DigitSegmentProps {
  segmentStates: string;  // The string indicating which segments are active, e.g., "1111111"
  height: number;         // The height of the digit
  color: string;          // The color of the active segments
  skew: boolean;          // Whether to apply the skewed style
}

const DigitSegment: React.FC<DigitSegmentProps> = ({ segmentStates, height, color, skew }) => {
  const styles = skew ? skewedSegmentStyle : segmentStyle;

  return (
    <div style={{ height, color, position: 'relative', width: height * 0.6 }}>
      {Object.keys(styles).map((segmentKey, index) => {
        const isActive = segmentStates[index] === '1';  // Ensure correct indexing
        const ss = styles[segmentKey];

        const segmentStyle = {
          clipPath: styles[segmentKey].clipPath,
          backgroundColor: isActive ? color : '#999999',  // Grey background when inactive
          width: '65%',
          height: `${height * 0.08}px`, // Adjust this based on your desired thickness
          position: 'absolute',
          marginTop: `${styles[segmentKey].marginTop}em`,
          marginLeft: `${styles[segmentKey].marginLeft}em`,
          transform: ss.transform,
        } as React.CSSProperties;

        return (
          <div key={ss.id} className={`segment segment-${ss.id.toLowerCase()}`} style={segmentStyle} />
        );
      })}
    </div>
  );
};

export default DigitSegment;
