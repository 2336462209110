/**
 * Copyright © 2024 Grant D. Powell and Parleii LLC
 *
 * This code is closed source and is intended solely for the use of Grant D. Powell or Parleii LLC.
 * All rights reserved. No part of this code may be reproduced, distributed, or transmitted
 * in any form or by any means without the prior written permission of the copyright owners.
 *
 * Grant D. Powell retains primary rights to this code, with Parleii LLC holding rights for internal use and development.
 * Any commercial use or distribution outside of Parleii LLC requires the explicit permission of Grant D. Powell.
 *
 * "Parleii LLC" refers to the legal entity and its authorized employees, contractors, and agents.
 *
 * This project includes open-source components licensed under MIT and Apache 2.0 licenses:
 * - @emotion/react (MIT)
 * - @emotion/styled (MIT)
 * - @mui/icons-material (MIT)
 * - @mui/material (MIT)
 * - @testing-library/jest-dom (MIT)
 * - @testing-library/react (MIT)
 * - @types/base-64 (MIT)
 * - @types/react-dom (MIT)
 * - @types/react (MIT)
 * - avrgirl-arduino (MIT)
 * - base-64 (Unlicense)
 * - eslint-config-react-app (MIT)
 * - js-chacha20 (MIT)
 * - react-7-segment-display (MIT)
 * - react-bulb (MIT)
 * - react-dom (MIT)
 * - react-scripts (MIT)
 * - react (MIT)
 * - serialterminal (MIT)
 * - typescript (Apache 2.0)
 * - web-vitals (Apache 2.0)
 *
 * The above licenses apply only to their respective components.
 * For licensing inquiries, please contact Grant D. Powell at grantdpowell911@gmail.com.
 */
import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  CssBaseline,
  Typography,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import Navbar from "./components/Navbar";
import TestingPage from "./components/TestingBar";
import StatusWindow from "./components/StatusWindow";
import Serial from "./modules/Serial";
import AutoUpdater from "./modules/AutoUpdater";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import "./styling/App.css";

const App: React.FC = () => {
  const [serial] = useState(new Serial()); // Initialize the Serial instance
  const [inputStates, setInputStates] = useState("0000000"); // State for input signals
  const [outputStates, setOutputStates] = useState("0000000"); // State for output signals
  const [isConnected, setIsConnected] = useState(false); // Track connection status
  const [showUpdater, setShowUpdater] = useState(false); // Track if the updater dialog should be shown
  const [connectionStatus, setConnectionStatus] = useState("Click to connect"); // Status message for connection
  const [firstResponseReceived, setFirstResponseReceived] = useState(false); // Track if initial data has been received
  const [deniedUpdate, setDeniedUpdate] = useState(false); // Track if firmware update was denied
  const [latestVersion] = useState("3v3"); // Store the latest firmware version

  const [error, setError] = useState<string | null>(null); // Error state for displaying messages

  const ch340Url =
    "https://digilab-one.vercel.app/bin/CH34x_Install_Windows_v3_4.zip";
  const firmwareUrl =
    "https://digilab-one.vercel.app/bin/ARDCODE.ino.with_bootloader.hex";

  //const paperOnRootApp = "#3320"; // Background color for the main paper component
  //const paperOnRoot = "#3326";

  //const rootPaperWidth = "80%"; // Width of the main paper component
  // this controlls the width of all content since they are all nested in this paper component

  const [isClockOn, setIsClockOn] = useState(true);

  useEffect(() => {
    serial.onReceive = (data: string) => {
      const [inputs, outputs] = data.trim().split("|");
      setInputStates(inputs);
      setOutputStates(outputs);

      if (!firstResponseReceived && inputs && outputs) {
        setFirstResponseReceived(true);
      }
    };

    serial.onFail = (errorMsg: string) => {
      setError(errorMsg || "An unexpected error occurred.");
      handleDisconnection(); // Handle disconnection when a failure occurs
      if (errorMsg.includes("Cannot send data")) {
        alert("Disconnected during testing, site will reload.");
        // Wait for the user to press OK before reloading
        window.location.reload();
      }
    };
  }, [serial, firstResponseReceived]);

  // Connect to the device and handle errors
  const connectToDevice = async () => {
    setConnectionStatus("Connecting...");
    setFirstResponseReceived(false);
    try {
      const success = await serial.requestPort();
      if (!success) {
        throw new Error("Failed to open serial port. No devices found.");
      }

      setIsConnected(true);
      setConnectionStatus("Connected");

      await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for the device to be ready

      if (!deniedUpdate) {
        const needsUpdate = await serial.checkForUpdate({ latestVersion });
        if (needsUpdate) {
          setShowUpdater(true);
          return; // Exit early to wait for user's decision in the update prompt
        }
      }

      // If no update is needed or update was denied previously, send '1'
      await serial.send("1"); // Request the initial state
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(
          error.message.includes("Failed to open serial port")
            ? "No devices found/selected, or the device is already in use."
            : error.message
        );
      }
      setIsConnected(false);
      setConnectionStatus("Disconnected, click to connect");
    }
  };
  // Disconnect from the device and handle errors
  const disconnectFromDevice = async () => {
    try {
      await serial.close();
      handleDisconnection();
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error.message);
      }
      setConnectionStatus("Connected");
    }
  };

  // Handle disconnection logic
  const handleDisconnection = () => {
    setIsConnected(false);
    setConnectionStatus("Disconnected, click to connect");
    setFirstResponseReceived(false);
    setInputStates("0000000");
    setOutputStates("0000000");
  };

  // Handle completion of firmware update
  const handleUpdateComplete = async () => {
    setShowUpdater(false);
    setDeniedUpdate(false);
    //await serial.send('1'); // Request the initial state after the update is complete
    setConnectionStatus("Connected");
  };

  // Handle denial of firmware update
  const handleUpdateDeny = async () => {
    setDeniedUpdate(true);
    setShowUpdater(false);
    await serial.send("1"); // Request the initial state even if the update was denied
    setConnectionStatus("Connected");
  };

  // Close the error Snackbar
  const handleCloseSnackbar = () => {
    setError(null);
  };

  return (
    <>
      <CssBaseline />

      <Box className="root-containter">
        <Navbar
          connectToDevice={connectToDevice}
          disconnectFromDevice={disconnectFromDevice}
          isConnected={isConnected}
          connectionStatus={connectionStatus}
        />


        <Paper elevation={0} className="root-paper">
          {isConnected && !firstResponseReceived && (
            <Paper elevation={3} className="connecting-live-view">
              <Typography variant="h6" align="center" fontWeight={'bold'} className="typography-centered">
                Connecting Live View...
              </Typography>
            </Paper>
          )}

          {isConnected && firstResponseReceived && (
            <StatusWindow
              inputStates={inputStates}
              outputStates={outputStates}
              isClockOn={isClockOn}
            />
          )}

          
            {isConnected ? (
              <TestingPage
                serial={serial}
                inputStates={inputStates}
                outputStates={outputStates}
                isClockOn={isClockOn}
                setIsClockOn={setIsClockOn}
              />
            ) : (
              <Paper elevation={3} className="waiting-for-connect">
                <Typography variant="h6" align="center" fontWeight={'bold'} className="typography-centered">
                  Please connect to the device to access the features.
                </Typography>
              </Paper>
            )}
          
        </Paper>

        {showUpdater && (
          <AutoUpdater
            serial={serial}
            firmwareUrl={firmwareUrl}
            latestVersion={latestVersion}
            onComplete={handleUpdateComplete}
            onDeny={handleUpdateDeny}
          />
        )}

        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>

      </Box>

      <Analytics />
      <SpeedInsights />
    </>
  );
};

export default App;
