/**
 * Copyright © 2024 Grant D. Powell and Parleii LLC
 *
 * This code is closed source and is intended solely for the use of Grant D. Powell or Parleii LLC. 
 * All rights reserved. No part of this code may be reproduced, distributed, or transmitted 
 * in any form or by any means without the prior written permission of the copyright owners.
 *
 * Grant D. Powell retains primary rights to this code, with Parleii LLC holding rights for internal use and development. 
 * Any commercial use or distribution outside of Parleii LLC requires the explicit permission of Grant D. Powell.
 * 
 * "Parleii LLC" refers to the legal entity and its authorized employees, contractors, and agents.
 *
 * This project includes open-source components licensed under MIT and Apache 2.0 licenses:
 * - @emotion/react (MIT)
 * - @emotion/styled (MIT)
 * - @mui/icons-material (MIT)
 * - @mui/material (MIT)
 * - @testing-library/jest-dom (MIT)
 * - @testing-library/react (MIT)
 * - @types/base-64 (MIT)
 * - @types/react-dom (MIT)
 * - @types/react (MIT)
 * - avrgirl-arduino (MIT)
 * - base-64 (Unlicense)
 * - eslint-config-react-app (MIT)
 * - js-chacha20 (MIT)
 * - react-7-segment-display (MIT)
 * - react-bulb (MIT)
 * - react-dom (MIT)
 * - react-scripts (MIT)
 * - react (MIT)
 * - serialterminal (MIT)
 * - typescript (Apache 2.0)
 * - web-vitals (Apache 2.0)
 *
 * The above licenses apply only to their respective components. 
 * For licensing inquiries, please contact Grant D. Powell at grantdpowell911@gmail.com.
 */
import React, { useState, useEffect } from 'react';
import CustomTest from './CustomTest';
import LabTesting from './LabTesting';
import StateMachine from './StateMachine';
import LabCreation from './LabCreation';
import { Button, Box, Paper, Typography } from '@mui/material';
import Serial from '../modules/Serial';

interface TestingPageProps {
  serial: Serial;              // Serial module instance for communication
  inputStates: string;         // Current input states
  outputStates: string;   
  isClockOn: boolean ;
  setIsClockOn: (value: boolean) => void;
     // Current output states
}

const TestingPage: React.FC<TestingPageProps> = ({ serial, inputStates, outputStates , isClockOn, setIsClockOn}) => {
  const [activeSection, setActiveSection] = useState<string | null>(null); // State to track which section is active
  const [isLocked, setIsLocked] = useState(false);                         // State to lock/unlock the section buttons
    
  const [isClockButtonLocked, setIsClockButtonLocked] = useState(false)                      // State to track if the clock is on

  const paperOnRoot = '#3326';           // Background color for the main paper component
  const parleiiGreen = '#4f5024';        // Parleii green color
  const buttonTextColor = 'white';       // Text color for the buttons

  useEffect(() => {
    // Turn on the clock by default when connected
    if (serial) {
      // serial.send('#');
      setIsClockOn(true);
    }
  }, [serial]);

  // Toggle the active section; only allow one section to be active at a time
  const toggleSection = (section: string) => {
    if (isLocked) return;

    if (activeSection === section) {
      setActiveSection(null);
    } else {
      setActiveSection(section);
    }
  };

  // Lock the buttons to prevent toggling while a section is active
  const lockButtons = () => {
    setIsLocked(true);
  };

  // Unlock the buttons to allow toggling between sections
  const unlockButtons = () => {
    setIsLocked(false);
  };

  // Toggle clock state
  const toggleClock = async () => {
    serial.send('#'); // Send command to toggle the clock
    setIsClockOn(!isClockOn); // Update clock state
    setIsClockButtonLocked(true); // Lock the button to prevent rapid toggling
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsClockButtonLocked(false); // Unlock the button after
    
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 , width:'100%'}}>
      
      {/* Navigation buttons for different sections */}
      <Paper elevation={3} 
        sx={{ 
          width: '100%', 
          maxWidth: 'none', // Ensure no max-width constraints
          padding: 3, 
          backgroundColor: paperOnRoot,
          border: 3,
          display: 'flex',
          flexDirection: 'row', 
          alignItems: 'center', 
          justifyContent: 'space-between'
        }}
      >
        <Button 
          variant="contained"
          onClick={() => toggleSection('CustomTest')}
          disabled={isLocked || (activeSection !== null && activeSection !== 'CustomTest')}
          sx={{ 
            backgroundColor: parleiiGreen, 
            color: buttonTextColor,
            textTransform: 'none'
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            {activeSection === 'CustomTest' ? "Hide Custom Test" : "Custom Test"}
          </Typography>
        </Button>

        <Button 
          variant="contained" 
          onClick={() => toggleSection('LabTesting')}
          disabled={isLocked || (activeSection !== null && activeSection !== 'LabTesting')}
          sx={{ 
            backgroundColor: parleiiGreen, 
            color: buttonTextColor, 
            ml: 2 ,
            textTransform: 'none'
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            {activeSection === 'LabTesting' ? "Hide Lab Testing" : "Lab Testing"}
          </Typography>
        </Button>

        <Button 
          variant="contained" 
          onClick={() => toggleSection('StateMachine')}
          disabled={isLocked || (activeSection !== null && activeSection !== 'StateMachine')}
          sx={{ 
            backgroundColor: parleiiGreen, 
            color: buttonTextColor, 
            ml: 2 ,
            textTransform: 'none'
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            {activeSection === 'StateMachine' ? "Hide State Machine" : "State Machine"}
          </Typography>
        </Button>

        <Button 
          variant="contained" 
          onClick={() => toggleSection('LabCreation')}
          disabled={isLocked || (activeSection !== null && activeSection !== 'LabCreation')}
          sx={{ 
            backgroundColor: parleiiGreen, 
            color: buttonTextColor, 
            ml: 2 ,
            textTransform: 'none'
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            {activeSection === 'LabCreation' ? "Hide Lab Creation" : "Lab Creation"}
          </Typography>
        </Button>
        
        
        
        {/* Button to toggle the clock state */}
        {/* <Button
          variant="contained"
          onClick={toggleClock}
          disabled={isClockButtonLocked || (activeSection !== null)}
          sx={{
            backgroundColor: isClockOn ? parleiiGreen : 'red', // Green if clock is on, red if off
            color: buttonTextColor,
            ml: 2,
            textTransform: 'none',
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            {isClockOn ? 'Clock & Status ON' : 'Clock & Status OFF'}
          </Typography>
        </Button> */}
        

      </Paper>

      {/* Render the active section's component */}
      {activeSection === 'CustomTest' && (
        <CustomTest serial={serial} initialRowCount={8} lockButtons={lockButtons} unlockButtons={unlockButtons} />
      )}

      {activeSection === 'LabTesting' && (
        <LabTesting lockButtons={lockButtons} unlockButtons={unlockButtons} serial={serial}/>
      )}

      {activeSection === 'StateMachine' && (
        <StateMachine lockButtons={lockButtons} unlockButtons={unlockButtons} serial={serial} isClockOn={isClockOn} toggleClock={toggleClock}/>
      )}

      {activeSection === 'LabCreation' && (
        <LabCreation />
      )}
    </Box>
  );
};

export default TestingPage;
