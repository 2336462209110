/**
 * Copyright © 2024 Grant D. Powell and Parleii LLC
 *
 * This code is closed source and is intended solely for the use of Grant D. Powell or Parleii LLC. 
 * All rights reserved. No part of this code may be reproduced, distributed, or transmitted 
 * in any form or by any means without the prior written permission of the copyright owners.
 *
 * Grant D. Powell retains primary rights to this code, with Parleii LLC holding rights for internal use and development. 
 * Any commercial use or distribution outside of Parleii LLC requires the explicit permission of Grant D. Powell.
 * 
 * "Parleii LLC" refers to the legal entity and its authorized employees, contractors, and agents.
 *
 * This project includes open-source components licensed under MIT and Apache 2.0 licenses:
 * - @emotion/react (MIT)
 * - @emotion/styled (MIT)
 * - @mui/icons-material (MIT)
 * - @mui/material (MIT)
 * - @testing-library/jest-dom (MIT)
 * - @testing-library/react (MIT)
 * - @types/base-64 (MIT)
 * - @types/react-dom (MIT)
 * - @types/react (MIT)
 * - avrgirl-arduino (MIT)
 * - base-64 (Unlicense)
 * - eslint-config-react-app (MIT)
 * - js-chacha20 (MIT)
 * - react-7-segment-display (MIT)
 * - react-bulb (MIT)
 * - react-dom (MIT)
 * - react-scripts (MIT)
 * - react (MIT)
 * - serialterminal (MIT)
 * - typescript (Apache 2.0)
 * - web-vitals (Apache 2.0)
 *
 * The above licenses apply only to their respective components. 
 * For licensing inquiries, please contact Grant D. Powell at grantdpowell911@gmail.com.
 */
import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import DigitSegment from './7-seg-digilab/components/DigitSegment';
import Bulb from './Bulb';
import SlideSwitch from './SlideSwitch';

interface StatusWindowProps {
  inputStates: string;         // String representing the current input states
  outputStates: string;        // String representing the current output states
  isClockOn: boolean;          // Boolean representing if the clock is on or off
}

const StatusWindow: React.FC<StatusWindowProps> = ({ inputStates, outputStates, isClockOn }) => {
  const [localInputStates, setLocalInputStates] = useState(inputStates);
  const [dotColor, setDotColor] = useState('black'); // State to manage the blinking dot's color

  const clockFrequency = 238.5; // Adjust the frequency to control the blinking speed (e.g., 500ms)
  // i calculated 125ms but is cleary to fast so i changed it to 238.5ms from visual feedback 
  // TODO : change the frequency to whatever the exacy period time is
  

  // Update local state whenever inputStates prop changes
  useEffect(() => {
    setLocalInputStates(inputStates);
  }, [inputStates]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (isClockOn) {
      // Start blinking when the clock is on
      intervalId = setInterval(() => {
        setDotColor((prev) => (prev === 'red' ? 'black' : 'red'));
      }, clockFrequency); // Adjust the interval to control the blinking frequency (e.g., 500ms)
    } else {
      // If clock is off, ensure the dot stays on black and stop blinking
      setDotColor('black');
      if (intervalId) clearInterval(intervalId);
    }

    // Cleanup the interval on unmount or when isClockOn changes
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isClockOn]);

  const paperOnRoot = '#3326';           // Background color for the main paper component
  const statusWindowTextColor = 'black'; // Text color for the status text

  // Handle toggle logic for the switches
  const handleToggle = (index: number) => {
    const updatedStates = localInputStates.split('').map((state, i) => (
      i === index ? (state === '1' ? '0' : '1') : state
    )).join('');
    setLocalInputStates(updatedStates);
  };

  // Render the input switches based on the input states
  const renderInputSwitches = () => {
    const paddedInputStates = localInputStates.padEnd(7, '0'); // Ensure there are always 7 characters

    return paddedInputStates.split('').map((state, index) => (
      <Box
        key={`input-${index}`}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '0 12px',
        }}
      >
        <SlideSwitch isOn={state === '1'} onToggle={() => handleToggle(index)} />
        <Typography variant="h6" sx={{ marginTop: '4px', textAlign: 'center' }}>
          S{index + 1}
        </Typography>
      </Box>
    ));
  };

  // Render the output lights based on the output states
  const renderOutputLights = () => {
    const paddedOutputStates = outputStates.slice(0, 7).padEnd(7, '0'); // Ensure there are always 7 characters

    return paddedOutputStates.split('').map((state, index) => (
      <Box
        key={`output-${index}`}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '0 12px',
        }}
      >
        <Box>
          <Bulb
            size={25} // Adjust the size as needed
            color={state === '1' ? 'red' : '#999999'} // Red for ON, gray for OFF
            glow={state === '1'} // Enable glow when ON
          />
          <Typography variant="h6" sx={{ marginTop: '4px', textAlign: 'center' }}>
            L{index + 1}
          </Typography>
        </Box>
      </Box>
    ));
  };

  // Render a 7-segment display based on the binary output states
  const render7SegmentDisplay = () => {
    return (
      <Box sx={{ position: 'relative', marginLeft: '20px', marginTop: '10px' }}>
        <DigitSegment segmentStates={outputStates.slice(0, 7)} height={200} color={'red'} skew={false} />
        {/* Blinking dot positioned at the bottom right of the 7-segment display */}
        {/* <Box sx={{ position: 'absolute', bottom: 7.5, right: -20 }}>
          <Bulb size={10} color={dotColor} glow={dotColor === 'red'} />
        </Box> */}
      </Box>
    );
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 3,
        backgroundColor: paperOnRoot,
        border: 3,
        color: statusWindowTextColor,
        width: '100%',
        height: 'auto',
        margin: '20px auto', // Center the paper horizontally
        display: 'flex',
        justifyContent: 'space-around', // Distribute space evenly including ends
        alignItems: 'center',
      }}
    >
      <Box sx={{ paddingX: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {renderInputSwitches()}       {/* Render the input switches */}
      </Box>
      <Box sx={{ paddingX: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {renderOutputLights()}        {/* Render the output lights */}
      </Box>
      <Box sx={{ paddingX: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {render7SegmentDisplay()}     {/* Render the 7-segment display with the blinking dot */}
      </Box>
    </Paper>
  );
};

export default StatusWindow;
